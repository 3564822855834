import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-elastic-carousel";
import Carousel1 from "react-elastic-carousel";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Item1 from "./Item1";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Helmet } from 'react-helmet';

export default function AboutUs({ category, handleLoading }) {
  const [fix, setFix] = useState(false);
  // useEffect(() => {
  //   document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
  // }, []);

  function setFixed() {
    if (window.scrollY >= 610) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff",
        text: "Schedule time with me",
        color: "#0069ff",
        textColor: "#ffffff",
        branding: false,
      });
    }
  };

  const [selects, setSelects] = useState("");
  const [credentials, setCredentials] = useState({
    fName: "",
    lName: "",
    mobile: "",
    email: "",
    city: "",
    lookingFor: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const xyz = () => {
    setCredentials({
      fName: credentials.fName,
      lName: credentials.lName,
      mobile: credentials.mobile,
      email: credentials.email,
      city: selects,
      lookingFor: credentials.lookingFor,
    });
  };
  const [alertInput, setAlertInput] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();
    if (credentials.fName === "") {
      setAlertInput(true);
    }
    if (credentials.lName === "") {
      setAlertInput(true);
    }
    if (credentials.mobile === "") {
      setAlertInput(true);
    }
    if (credentials.email === "") {
      setAlertInput(true);
    }
    if (credentials.city === "") {
      setAlertInput(true);
    }
    if (credentials.lookingFor === "") {
      setAlertInput(true);
    } else {
      setAlertInput(false);
      // const formData = new FormData(e.target);
      axios
        .post(
          `https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`
        )
        // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
        // http://localhost/api/post-endpoint
        .then((response) => {
          setFormResponse(response.data.message);
          toast(
            "Thank For Consult with us our executive will contact you soon🙂",
            {
              // toast('🦄 Wow so easy!', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          hideModal();
          setIsClicked(true);
          const elements = document.getElementsByClassName("modal-backdrop");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.toggle("new-class");
            const divToRemove = elements[i];
            divToRemove.remove();
          }
          setTimeout(() => {
            setIsClicked(false);
          }, 1000);
          setCredentials({
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
          });
        })
        .catch((error) => {
          console.error(error);
          setFormResponse("Error sending OTP");
        });
    }
  };

  const hideModal = () => {
    // setTimeout(() => {
    //     window.location.href('/');
    // }, 1000);
  };

  const [fix1, setFix1] = useState(false);

  function setFixed1() {
    if (window.scrollY >= 200) {
      setFix1(true);
    } else {
      setFix1(false);
    }
  }
  window.addEventListener("scroll", setFixed1);

  const [hideBox, setHideBox] = useState(true);
  const hideBoxfunc = () => {
    setHideBox(false);
  };

  const breakPoints1 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3.5 },
    { width: 768, itemsToShow: 3.5 },
    { width: 1200, itemsToShow: 3.5 },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const items = [
    {
      quote:
        "I am truly amazed by the level of craftsmanship and attention to detail that 99SquareWall brought to my home. From the initial design discussions to the final touches, their team demonstrated professionalism and dedication. The result is a living space that not only reflects my style but also exceeds my expectations. I can't thank them enough for turning my house into a place I'm proud to call home.",
      name: "Pritesh Mahajan",
      place: "Kothrud, Pune",
    },
    {
      quote:
        "Working with 99SquareWall was a fantastic experience. Their expertise in commercial construction is evident in the stunning space they delivered for my business. The entire process was seamless, from the creative design phase to the flawless execution. I appreciate their commitment to quality and their ability to meet deadlines. I highly recommend 99SquareWall to anyone seeking top-notch construction services.",
      name: "Suresh Bhujbal",
      place: "Hinjewadi, Pune",
    },
    {
      quote:
        "I had the pleasure of collaborating with 99SquareWall on a residential project, and I'm thrilled with the results. Their team took the time to understand my vision and translated it into a design that perfectly captures my style. The attention to detail, quality of materials, and craftsmanship are remarkable. 99SquareWall transformed my space into a functional and beautiful home.",
      name: "Rohini Tyagi",
      place: "Koregaon Park, Pune",
    },
    {
      quote:
        "From the very beginning, 99SquareWall demonstrated a deep understanding of my aspirations for my new home. Their architects and builders worked in harmony, resulting in a seamless transformation. The dedication to achieving perfection and the precision in execution set them apart. The final result is a home that not only meets but surpasses my dreams.",
      name: "Rohit Mishra",
      place: "Punawale, Pune",
    },
    {
      quote:
        "I had the privilege of partnering with 99SquareWall on a commercial project, and their commitment to excellence is unparalleled. Their team combines aesthetics with functionality effortlessly. They transformed my vision into a space that captures the essence of my business. I am grateful for their professionalism, creativity, and dedication.",
      name: "Mahira Baig",
      place: "Koregaon Park, Pune",
    },
    {
      quote:
        "My experience with 99SquareWall was exceptional. Their architects and designers truly understand how to harmonize beauty and practicality. From the initial consultation to the final walk-through, their attention to detail and dedication to quality were evident. They turned my vision into reality while ensuring functionality and aesthetics were perfectly balanced.",
      name: "Suman Kothari",
      place: "Talegaon Dabhade, Pune",
    },
  ];

  const handleTransitionEnd = () => {
    const { current } = carouselRef;
    if (current) {
      const newIndex = current.getActiveIndex();
      setCurrentIndex(newIndex);

      // Manually reset the carousel to the beginning when it reaches the end
      if (newIndex === items.length - 1) {
        current.goTo(0);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>About Company - Pune's No.01 Lock and key construction company</title>
        <meta name="description" content="Welcome to 99SquareWall, your premier construction company specializing in turning dreams into reality. Explore our exceptional residential and commercial projects. Schedule a consultation today to begin your journey to extraordinary spaces." />
        <meta name="keywords" content="construction, residential projects, commercial projects, 99SquareWall, home construction, building services" />
      </Helmet>
      <Header />

      <div
        style={{
          height: "392px",
          width: "100%",
        }}
        className="about-us"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            // color: 'white',
            // font: 'bold 6.5em/2em monospace',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(to top, rgb(0 0 0 / 73%), rgb(255 255 255 / 0%)), url(/img/h.jpg) center top no-repeat",
          }}
        >
          <p>About Us</p>
        </div>
      </div>

      <div className="main">
        <div className="about-heading-passage">
          <p>
            Crafting Dreams, Building Realities:
            <br />
            Discover 99SquareWall
          </p>
          <span>
            At 99SquareWall, we are more than just a construction company – we
            are creators of possibilities. With a rich legacy and a commitment
            to excellence, we turn aspirations into tangible structures that
            stand as testaments to innovation and precision.
          </span>
        </div>

        <div className="founder-info-section">
          <div className="founder-img">
            <img src="/img/8104.jpg" alt="" />
          </div>
          <div className="right-founder">
            <h3>Elevate Your Vision with 99SquareWall</h3>
            <p>
              For over 5+ years, 99SquareWall has been at the forefront of the
              construction industry, shaping environments that inspire, empower,
              and transform. Our team of dedicated architects, engineers, and
              artisans work in harmony to design and build spaces that not only
              reflect your dreams but also elevate them.
              <br />
              <br />
              With a blend of creativity and functionality, we specialize in
              crafting residential havens and commercial spaces that align with
              your unique needs. At 99SquareWall, we believe in the power of
              partnership. Our collaborative approach ensures that your vision
              remains at the heart of every decision we make.
              <br />
              <br />
              Driven by values of integrity, transparency, and unwavering
              commitment, we deliver beyond expectations. Each project we
              undertake is a testament to our dedication to quality
              craftsmanship and innovative design. Join us on a journey where
              your dreams become the cornerstone of our creations.
              <br />
              <br />
              Welcome to 99SquareWall – where we craft dreams, build realities,
              and redefine what's possible in the world of construction.
            </p>
          </div>
        </div>

      </div>

      <div style={{ background: "rgb(228, 236, 249)",padding: "80px 0px" }}>
        <div className="about-four-box" style={{ maxWidth: "1320px", height: "100%", margin: "0 auto", marginTop: "15px", }}>
          <div style={{ display: "flex" }}>
            <div className="box-1">
              <div className="img">
                <img src="/img/empowering.png" alt="mission" />
              </div>
              <h6>Empowering Possibilities</h6>
              <p>
                Unveiling potential through transformative spaces, guiding our
                clients' visions into reality while setting new standards for
                innovation, sustainability, and design.
              </p>
            </div>
            <div className="box-1">
              <div className="img">
                <img src="/img/crafting.png" alt="mission" />
              </div>
              <h6>Crafting Inspired Environments</h6>
              <p>
                Dedicated to shaping environments that inspire, enrich, and
                endure. Our mission is to create spaces that mirror aspirations,
                fostering growth and lasting memories.
              </p>
            </div>
            <div className="box-1">
              <div className="img">
                <img src="/img/integrity.png" alt="mission" />
              </div>
              <h6>Integrity Drives Excellence</h6>
              <p>
                We stand by unwavering integrity in every endeavor. Excellence
                is our hallmark – from design to execution, transparency, and
                building lasting partnerships.
              </p>
            </div>
            <div className="box-1">
              <div className="img">
                <img src="/img/teamwork.png" alt="mission" />
              </div>
              <h6>Teamwork Shapes Success</h6>
              <p>
                Collaboration is key. Our diverse team thrives on shared
                expertise, collective creativity, and a dedication to creating
                outcomes that inspire and endure.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* --========== Testimonials Start ==========-- */}
      <section id="testimonials" className="testimonials testimonials-bg">
        <div className="container testimonialcontainer">
          <div className="section-title">
            <h1 style={{ color: "#fff" }}>
              What they are<font color="#fff"> saying about</font> us
            </h1>
          </div>
          <div className="mb">
            <Carousel1
              ref={carouselRef}
              onTransitionEnd={handleTransitionEnd}
              breakPoints={breakPoints1}
              pagination={false}
              showArrows={false}
              enableSwipe={true}
              enableAutoPlay={true}
              autoPlaySpeed={3000}
              onNextStart={true}
            >
              {items.map((item, index) => (
                <Item1>
                  <div tabIndex="-1" className="sc-qRumB hPSzWg">
                    <div className="testimonial-item">
                      <p>
                        <span>"</span>
                        {item.quote}
                        <span>"</span>
                      </p>
                      <h3>{item.name}</h3>
                      <h4>{item.place}</h4>
                    </div>
                  </div>
                </Item1>
              ))}
            </Carousel1>
          </div>
        </div>
      </section>
      {/* --========== Testimonials End ==========-- */}

      <Footer />
    </>
  );
}

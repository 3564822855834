import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${"" /* height: 100%; */}
  width: 100%;
  ${"" /* background: #eaf5f9; */}
  ${"" /* padding-top: 4rem; */}
  border-radius: 15px;
  margin-right: 0px;
  //   background-color: #00008B;
  //   color: #fff;
  // margin: 0 15px;
  // font-size: 4em;
`;

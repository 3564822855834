import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 370px;
  width: 100%;
  border-radius: 15px;
  margin-right: 10px;
`;
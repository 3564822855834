import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  let navigate = useNavigate();
  let location = useLocation();

  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 210) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  const [fix1, setFix1] = useState(false);

  function setFixed1() {
    if (window.scrollY >= 200) {
      setFix1(true);
    } else {
      setFix1(false);
    }
  }
  window.addEventListener("scroll", setFixed1);

  // const [fullName, setFullName] = useState('');
  // const [email, setEmail] = useState('');
  const [credentials, setCredentials] = useState({
    fullName: "",
    email: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [whatsappUpdate, setWhatsappUpdate] = useState("on");
  const [constructionUpdate, setConstructionUpdate] = useState("yes");

  const handleWhatsappUpdate = () => {
    if (whatsappUpdate === "off") {
      setWhatsappUpdate("on");
    } else {
      setWhatsappUpdate("off");
    }
  };
  const handleConstructionUpdateYes = () => {
    setConstructionUpdate("yes");
  };
  const handleConstructionUpdateNo = () => {
    setConstructionUpdate("no");
  };

  const handleMobile = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 10) {
      setMobile(inputValue);
    }
  };
  // Handler to allow only numeric input
  const handleInputChange = (e) => {
    const value = e.target.value;
    let inputValue = e.target.value;
    if (inputValue.length <= 10) {
      // Allow only numeric values
      if (!isNaN(value) && !value.includes('e') && !value.includes('E') && !value.includes('+') && !value.includes('-')) {
        setMobile(value);
      }
    }
  };

  // Prevent default behavior for non-numeric keys
  const handleKeyDown = (e) => {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };
  const handlePincode = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 6) {
      setPincode(inputValue);
    }
  };
  const [alertInput, setAlertInput] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const handleIsClickedTrue = () => {
    setLeftToRightTab(false);
    setTimeout(() => {
      setIsClicked(true);
    }, 200);
  };
  const handleClose = async (e) => {
    setIsClicked(false);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (credentials.fullName === "") {
      setAlertInput(true);
    }
    if (credentials.email === "") {
      setAlertInput(true);
    }
    if (mobile === "") {
      setAlertInput(true);
    }
    if (pincode === "") {
      setAlertInput(true);
    } else {
      setAlertInput(false);
      // const formData = new FormData(e.target);
      axios
        .post(
          `https://www.99squarewall.com/consultation.php?fullName=${credentials.fullName}&email=${credentials.email}&mobile=${mobile}&pincode=${pincode}&whatsappUpdate=${whatsappUpdate}&constructionUpdate=${constructionUpdate}`
        )
        // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
        // http://localhost/api/post-endpoint
        .then((response) => {
          setFormResponse(response.data.message);
          // setShowOTP(true);
          // toast('Thank For Consult with us our executive will contact you soon🙂');
          toast(
            "Thank For Consult with us our executive will contact you soon🙂",
            {
              // toast('🦄 Wow so easy!', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setIsClicked(true);
          const elements = document.getElementsByClassName("modal-backdrop");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.toggle("new-class");
            const divToRemove = elements[i];
            divToRemove.remove();
          }
          setTimeout(() => {
            setIsClicked(false);
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          setFormResponse("Error sending OTP");
        });
    }
  };
  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff",
        text: "Schedule time with me",
        color: "#0069ff",
        textColor: "#ffffff",
        branding: false,
      });
    }
  };

  const [checkbox, setCheckbox] = useState(false);
  const handleCheck = () => {
    if (checkbox === false) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };

  const [leftToRightTab, setLeftToRightTab] = useState(false);
  const handleLeftToRightTab = () => {
    if (leftToRightTab === false) {
      setLeftToRightTab(true);
    } else {
      setLeftToRightTab(false);
    }
  };
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const swipeDistance = touchEndX.current - touchStartX.current;

    if (swipeDistance > 50) {
      // If swiped to the right, close the navbar
      setLeftToRightTab(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* <!-- Modal --> */}
      {isClicked === true && (
        <>
          <div className={isClicked === true ? "modal-bg" : "modal-bg none"}>
            <div className="modal-form">
              <div className="modal-container">
                <div className="modal-middle">
                  <p className="heading">Let our experts help you!</p>
                  <button onClick={handleClose}>
                    <img src="/img/letter-x.gif" />
                  </button>
                </div>
                <div className="modal-bottom">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">
                        Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Your Full Name"
                        value={credentials.fullName}
                        onChange={onChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Eg: myname@gmail.com"
                        value={credentials.email}
                        onChange={onChange}
                      />
                    </div>
                    <div className="row align-items-center mb-3">
                      <div className="col-auto mobile">
                        <label htmlFor="mobile" className="form-label">
                          Mobile Number*
                        </label>
                        <div className="mobile-container">
                          <img src="/img/india.png" />
                          <p>+91</p>
                        </div>
                        <input
                          type="number"
                          className="form-control2"
                          id="mobile"
                          name="mobile"
                          placeholder="10 digits"
                          value={mobile}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          required
                        />
                      </div>
                      <div className="col-auto">
                        <label htmlFor="pincode" className="form-label">
                          Pin Code*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="6 digit pin code"
                          value={pincode}
                          onChange={handlePincode}
                        />
                      </div>
                    </div>
                    <div className="whatsapp-update mb-3">
                      <label htmlFor="fullName" className="form-label mb-3">
                        Update me on WhatsApp
                      </label>
                      <button type="button" onClick={handleWhatsappUpdate}>
                        <img src="/img/whatsapp1.png" alt="" />
                        <p className={whatsappUpdate === "off" ? "" : "on"}>
                          {whatsappUpdate === "off" ? "off" : "on"}
                        </p>
                      </button>
                    </div>
                    <div className="any-construction">
                      <p>Do you own a plot ?</p>
                      <div className="yes-no-button">
                        <button
                          type="button"
                          className={
                            constructionUpdate === "yes"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateYes}
                        >
                          yes
                        </button>
                        <button
                          type="button"
                          className={
                            constructionUpdate === "no"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateNo}
                        >
                          no
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "11px",
                        marginBottom: "-7px",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="termCondition"
                        id="termCondition"
                        className="myinput"
                        onClick={handleCheck}
                      />
                      <label
                        htmlFor="termCondition"
                        className="termConditionLabel"
                        onClick={handleCheck}
                      >
                        *By submitting this form, I confirm that I have read and
                        agreed to accept 99squarewall{" "}
                        <a href="">privacy policy</a>
                      </label>
                    </div>
                    <button
                      type="button"
                      className="final-button"
                      onClick={handleClick}
                    >
                      Start Building
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* --========== Header Start ==========-- */}
      <header
        id="header"
        className={
          fix
            ? "d-flex align-items-center header-transparent fixed"
            : "d-flex align-items-center header-transparent"
        }
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <img
                src="/img/Logo-01.png"
                alt=""
              />
            </Link>
          </div>
          <nav id="navbar" className="navbar">
            <ul style={{ marginRight: "28px" }} className="responsive-none">
              <li>
                <a
                  className="nav-link scrollto"
                  href="/"
                  style={{ color: fix ? "black" : "black" }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="nav-link scrollto"
                  href="/company/about-us"
                  style={{ color: fix ? "black" : "black" }}
                >
                  About us
                </a>
              </li>
              {/* <li>
                <Link target="_blank" to="/blog" className="nav-link scrollto" style={{color: fix ? 'black' : 'black'}}>Blog</Link>
              </li> */}
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Our Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="dropdown-item">
                    <Link target="_blank" to="/homeConstruction" className="nav-link scrollto" style={{color: fix ? 'black' : 'black'}}>Home Construction</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link target="_blank" to="/commercialBuilding" className="nav-link scrollto" style={{color: fix ? 'black' : 'black'}}>Commercial Construction</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link target="_blank" to="/interior" className="nav-link scrollto" style={{color: fix ? 'black' : 'black'}}>Interior Services</Link>
                  </li>
                  <li className="dropdown-item">
                    <Link target="_blank" to="/renovation" className="nav-link scrollto" style={{color: fix ? 'black' : 'black'}}>Renovation</Link>
                  </li>
                </div>
              </li> */}
              {/* <li>
                <Link className="nav-link scrollto" target="_blank" to="/our-projects" style={{color: fix ? 'black' : 'black'}}>Projects</Link>
              </li> */}
              <li>
                <Link
                  className="nav-link scrollto"
                  target="_blank"
                  to="https://wa.me/917499295173"
                  style={{ color: fix ? "black" : "black" }}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <button className="header-btn" type="button">
                  <a
                    className="nav-link scrollto"
                    href="tel:7499295173"
                    style={{ color: fix ? "black" : "black" }}
                  >
                    +91 7499295173
                  </a>
                </button>
              </li>
            </ul>
            <ul className="responsive-none">
              <li>
                <button onClick={handleIsClickedTrue} className="header-btn">
                  <Link
                    className="nav-btn"
                    style={{ color: fix ? "black" : "black" }}
                  >
                    Start Building
                    <span><i class="fa-solid fa-arrow-right"></i></span>
                  </Link>
                </button>
              </li>
            </ul>
            <ul className="responsive-display" onClick={handleLeftToRightTab}>
              <li>
                <FontAwesomeIcon icon={faBars} />
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <div
        className={
          leftToRightTab === false
            ? "left-to-right-tab"
            : "left-to-right-tab active"
        }
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div onClick={() => setLeftToRightTab(false)}>
          <h3
            style={{
              float: "right",
              paddingRight: "20px",
              paddingTop: "10px",
              color: "white",
            }}
          >
            x
          </h3>
        </div>
        <nav id="navbar" className="navbar">
          <ul style={{ marginRight: "28px" }}>
            <li>
              <Link
                target="_blank"
                className="nav-link scrollto"
                to="/"
                style={{ color: fix ? "black" : "black" }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="/company/about-us"
                style={{ color: fix ? "black" : "black" }}
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                target="_blank"
                to="https://wa.me/917499295173"
                style={{ color: fix ? "black" : "black" }}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <button className="header-btn" type="button">
                <a
                  className="nav-link scrollto"
                  href="tel:7499295173"
                  style={{ color: fix ? "black" : "black" }}
                >
                  +91 7499295173
                </a>
              </button>
            </li>
            <li>
              <button onClick={handleIsClickedTrue} className="header-btn">
                <Link
                  className="nav-btn"
                  style={{ color: fix ? "black" : "black" }}
                >
                  Start Building
                </Link>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* --========== Header End ==========-- */}
    </>
  );
}

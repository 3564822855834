import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import Carousel1 from 'react-elastic-carousel';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Item1 from './Item1';
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function Projects({ category }) {
    useEffect(() => {
        document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
    }, []);
  
    const [fix, setFix] = useState(false);
    
    function setFixed() {
        if (window.scrollY >= 610) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);

    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: false });
        }
    };

    const [selects, setSelects] = useState("");
    const [credentials, setCredentials] = useState(
        {
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
        }
    );
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const xyz = () => {
        setCredentials(
            {
                fName: credentials.fName,
                lName: credentials.lName,
                mobile: credentials.mobile,
                email: credentials.email,
                city: selects,
                lookingFor: credentials.lookingFor,
            }
        )
    }
    const [alertInput, setAlertInput] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = async (e) => {
        e.preventDefault();
        if (credentials.fName === '') {
            setAlertInput(true)
        }if (credentials.lName === '') {
            setAlertInput(true)
        }if (credentials.mobile === '') {
            setAlertInput(true)
        }if (credentials.email === '') {
            setAlertInput(true)
        }if (credentials.city === '') {
            setAlertInput(true)
        }if (credentials.lookingFor === '') {
            setAlertInput(true)
        } else {
            setAlertInput(false);
            // const formData = new FormData(e.target);
            axios.post(`https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // http://localhost/api/post-endpoint
            .then(response => {
                setFormResponse(response.data.message);
                toast('Thank For Consult with us our executive will contact you soon🙂', {
                    // toast('🦄 Wow so easy!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                hideModal();
                setIsClicked(true);
                const elements = document.getElementsByClassName('modal-backdrop');    
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('new-class');
                    const divToRemove = elements[i];
                    divToRemove.remove();
                }
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
                setCredentials(
                    {
                        fName: "",
                        lName: "",
                        mobile: "",
                        email: "",
                        city: "",
                        lookingFor: "",
                    }
                )
            })
            .catch(error => {
                console.error(error);
                setFormResponse('Error sending OTP');
            });
        }
    }

    const hideModal = () => {
        // setTimeout(() => {
        //     window.location.href('/');
        // }, 1000);
    }

    const [fix1, setFix1] = useState(false);
    
    function setFixed1() {
        if (window.scrollY >= 200) {
            setFix1(true);
        } else {
            setFix1(false);
        }
    }
    window.addEventListener("scroll", setFixed1);
    
    const [hideBox, setHideBox] = useState(true);
    const hideBoxfunc = () => {
        setHideBox(false);
    }

    const breakPoints1 = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 3.5 },
        { width: 768, itemsToShow: 3.5 },
        { width: 1200, itemsToShow: 3.5 },
    ]
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const items = [
        {
            quote: "I am truly amazed by the level of craftsmanship and attention to detail that 99SquareWall brought to my home. From the initial design discussions to the final touches, their team demonstrated professionalism and dedication. The result is a living space that not only reflects my style but also exceeds my expectations. I can't thank them enough for turning my house into a place I'm proud to call home.",
            name: "Pritesh Mahajan",
            place: "Kothrud, Pune",
        },
        {
            quote: "Working with 99SquareWall was a fantastic experience. Their expertise in commercial construction is evident in the stunning space they delivered for my business. The entire process was seamless, from the creative design phase to the flawless execution. I appreciate their commitment to quality and their ability to meet deadlines. I highly recommend 99SquareWall to anyone seeking top-notch construction services.",
            name: "Suresh Bhujbal",
            place: "Hinjewadi, Pune",
        },
        {
            quote: "I had the pleasure of collaborating with 99SquareWall on a residential project, and I'm thrilled with the results. Their team took the time to understand my vision and translated it into a design that perfectly captures my style. The attention to detail, quality of materials, and craftsmanship are remarkable. 99SquareWall transformed my space into a functional and beautiful home.",
            name: "Rohini Tyagi",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "From the very beginning, 99SquareWall demonstrated a deep understanding of my aspirations for my new home. Their architects and builders worked in harmony, resulting in a seamless transformation. The dedication to achieving perfection and the precision in execution set them apart. The final result is a home that not only meets but surpasses my dreams.",
            name: "Rohit Mishra",
            place: "Punawale, Pune",
        },
        {
            quote: "I had the privilege of partnering with 99SquareWall on a commercial project, and their commitment to excellence is unparalleled. Their team combines aesthetics with functionality effortlessly. They transformed my vision into a space that captures the essence of my business. I am grateful for their professionalism, creativity, and dedication.",
            name: "Mahira Baig",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "My experience with 99SquareWall was exceptional. Their architects and designers truly understand how to harmonize beauty and practicality. From the initial consultation to the final walk-through, their attention to detail and dedication to quality were evident. They turned my vision into reality while ensuring functionality and aesthetics were perfectly balanced.",
            name: "Suman Kothari",
            place: "Talegaon Dabhade, Pune",
        },
    ];
  
    const handleTransitionEnd = () => {
        const { current } = carouselRef;
        if (current) {
            const newIndex = current.getActiveIndex();
            setCurrentIndex(newIndex);
            
            // Manually reset the carousel to the beginning when it reaches the end
            if (newIndex === items.length - 1) {
                current.goTo(0);
            }
        }
    };

  return (
    <>
        <Header />

        <div
            style={{
                height: '392px',
                width: '100%',
            }}
            className="about-us projects-us"
        >
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    // color: 'white',
                    // font: 'bold 6.5em/2em monospace',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(to top, rgb(0 0 0 / 73%), rgb(255 255 255 / 0%)), url(/img/h.jpg) center top no-repeat',
                    borderBottomRightRadius: '50px',
                    borderBottomLeftRadius: '50px'                
                }}
            >
                <div>
                    <p>Our Project's</p>
                    <span
                        style={{
                            color: '#ffffff',
                            fontSize: '16px',
                            margin: '0 auto',
                            textAlign: 'center',
                            width: '46rem',
                            display: 'block',
                        }}
                    >
                        Discover our Home Construction Services, where ideas become abodes. With expertise in every detail, we craft homes that inspire and endure. From design to delivery, trust us to build the haven you envision.
                    </span>
                </div>
            </div>
        </div>

        <div className="main">
            <section className="project-card-section pricing">
                <div className="project-card-container container">
                    <Link to="/projects/projects-details/asian-paints" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                    <Link to="/" className="project-card" style={{
                        backgroundImage: 'url(example-img/project-sample.png)',
                        backgroundSize: 'cover',
                    }}>
                        <div className="project-text">
                            <p>Lorem, ipsum</p>
                        </div>
                    </Link>
                </div>
            </section>
        </div>
        
        <Footer/>

    </>
  )
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function BlogContent({ category }) {
  const [fix, setFix] = useState(false);
  useEffect(() => {
      document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
  }, []);

  function setFixed() {
    if (window.scrollY >= 610) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff",
        text: "Schedule time with me",
        color: "#0069ff",
        textColor: "#ffffff",
        branding: false,
      });
    }
  };

  const [selects, setSelects] = useState("");
  const [credentials, setCredentials] = useState({
    fName: "",
    lName: "",
    mobile: "",
    email: "",
    city: "",
    lookingFor: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const xyz = () => {
    setCredentials({
      fName: credentials.fName,
      lName: credentials.lName,
      mobile: credentials.mobile,
      email: credentials.email,
      city: selects,
      lookingFor: credentials.lookingFor,
    });
  };
  const [alertInput, setAlertInput] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();
    if (credentials.fName === "") {
      setAlertInput(true);
    }
    if (credentials.lName === "") {
      setAlertInput(true);
    }
    if (credentials.mobile === "") {
      setAlertInput(true);
    }
    if (credentials.email === "") {
      setAlertInput(true);
    }
    if (credentials.city === "") {
      setAlertInput(true);
    }
    if (credentials.lookingFor === "") {
      setAlertInput(true);
    } else {
      setAlertInput(false);
      // const formData = new FormData(e.target);
      axios.post(`https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
      // axios
      //   .post(
      //     `http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`
      //   )
        // http://localhost/api/post-endpoint
        .then((response) => {
          setFormResponse(response.data.message);
          toast('Thank For Consult with us our executive will contact you soon🙂', {
            // toast('🦄 Wow so easy!', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setIsClicked(true);
          const elements = document.getElementsByClassName("modal-backdrop");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.toggle("new-class");
            const divToRemove = elements[i];
            divToRemove.remove();
          }
          setTimeout(() => {
            setIsClicked(false);
          }, 1000);
          setCredentials({
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
          });
        })
        .catch((error) => {
          console.error(error);
          setFormResponse("Error sending OTP");
        });
    }
  };

  return (
    <>
            <Header />

      {/* blog content start */}
        <div className="main">
          {/* link top */}
            <div id="deskProjBreadCrumb" className="desktopBreadCrumb__brdcrumbContainer">
              <div>
                <div id="breadCrumbItemWrapper" className="desktopBreadCrumb__breadCrumbItem">
                  <div>
                    <span>
                      <Link className="desktopBreadCrumb__linkStyle" to="/" target="" title="Home" itemprop="url">
                        <span itemprop="title">Home</span>
                      </Link>
                    </span>
                  </div>
                  <span className="desktopBreadCrumb__rPin">›</span>
                </div>
                <div id="breadCrumbItemWrappercity" className="desktopBreadCrumb__breadCrumbItem">
                  <div>
                    <span>
                      <Link className="desktopBreadCrumb__linkStyle" to="/blog" target="_blank" title="New Projects in Pune" itemprop="url">
                        <span itemprop="title">Blog</span>
                      </Link>
                    </span>
                  </div>
                  <span className="desktopBreadCrumb__rPin">›</span>
                </div>
                <div id="breadCrumbItemWrappercity" className="desktopBreadCrumb__breadCrumbItem">
                  <div>
                    <span>
                      <div className="desktopBreadCrumb__linkStyle" target="_blank" title="New Projects in Pune" itemprop="url">
                        <span itemprop="title">Blog-Content</span>
                      </div>
                    </span>
                  </div>
                  <span className="desktopBreadCrumb__rPin">›</span>
                </div>
                <div id="projectNameTab" className="desktopBreadCrumb__breadCrumbItem desktopBreadCrumb__linkStyle">blog name</div>
              </div>
            </div>
          {/* link top */}
          {/* blog content start */}
            <div className="bloContentSection">
              <div className="blogContentImg">
                <img src="/img/h.jpg" alt="" />
              </div>
              <div className="mainarticla">
                <div className="leftsec margintopmlft">
                  <article>
                    <div className="card1 topcontent">
                      <div className="leftsec__wrapper">
                        <h1 className="main__title">Know Your Interior Design Style As Per Your Zodiac Sign</h1>
                        <article className="leftsec__wrapper__date">Published:
                          <time className="leftsec__text-clr" datetime="2023-08-19T18:04:11+05:30">
                          Aug 19, 2023, 18:04
                          IST
                          </time>
                        </article>
                      </div>

                      <div className="leftsec__contenthold">
                        <div className="leftsec__wrapper2">

                          <div className="article__content">
                            <p dir="ltr" style={{ textAlign: 'justify' }}><em>Did you know your interior design can be made as per your zodiac sign? Learn how to decorate your home according to your zodiac sign and lend it your personal touch.</em></p>
                            <p dir="ltr" style={{ textAlign: 'justify' }}><span>Astrology has remained popular over the centuries and is an essential part of the spiritual world. We Indians, always look for our daily horoscopes based on our birth chart, don’t we? Well, most of us rely on astrologers while making big and even small decisions. It could be seeking a perfect job, discovering a compatible partner or decorating a home that is in harmony with our personality.</span></p>
                            <p dir="ltr" style={{ textAlign: 'justify' }}><span>You would be surprised to know that zodiac interiors have become an appealing trend. These days, most home makers look for the interior design style best suited to their sun sign and work towards making their homes zodiac friendly. This brings peace, positivity and all the good things into your homes and lives.</span></p>
                            <p dir="ltr" style={{ textAlign: 'justify' }}><span>So discover below what interior style is perfect as per your zodiac sign, and get ready to improve your personal and professional life.</span></p>
                            <div className="repeat">
                              <h2 dir="ltr" id="Home_Interiors_As_Per_Your_Zodiac_Sign">
                                <span>Home Interiors As Per Your Zodiac Sign</span>
                              </h2>
                              <p dir="ltr" style={{ textAlign: 'justify' }}><span>Find out what interior design style would suit you the most according to your zodiac sign:</span></p>
                              <img src="https://img.staticmb.com/mbcontent/images/uploads/2023/8/Interior-Design-for-Aries-Zodiac-Interiors.jpg" alt="repeatImg" />
                            </div>
                          </div>
                          
                        </div>
                      </div>

                    </div>
                  </article>
                </div>
                <div className="rightsec">
                  <a href="https://www.magicbricks.com/decor?inc=KnowMore" className="rightsec__idPromo">
                    <div className="rightsec__idPromo__title">Transform Your Bedroom with Magicbricks Home Interior Services!</div>
                    <div className="rightsec__idPromo__row">
                      <ul className="rightsec__idPromo__list">
                        <li className="rightsec__idPromo__list-item">Home Construction Services</li>
                        <li className="rightsec__idPromo__list-item">Commercial Construction Services</li>
                      </ul>
                      <Link to='/' className="rightsec__idPromo__cta">Know More</Link>
                    </div>
                  </a>
                  <div className="shuffleArticle">
                    <h2>
                      Latest from our Blogs:
                    </h2>
                    <div className="article-card">
                      <img src="/img/blog1.png" alt="" />
                      <div className="article-card-content">
                        <label>The journey of thousand miles begins with single steps.</label>
                        <div className="blogBtn">
                          <button>Read More</button>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="article-card">
                      <img src="/img/blog1.png" alt="" />
                      <div className="article-card-content">
                        <label>The journey of thousand miles begins with single steps.</label>
                        <div className="blogBtn">
                          <button>Read More</button>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="article-card">
                      <img src="/img/blog1.png" alt="" />
                      <div className="article-card-content">
                        <label>The journey of thousand miles begins with single steps.</label>
                        <div className="blogBtn">
                          <button>Read More</button>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="article-card">
                      <img src="/img/blog1.png" alt="" />
                      <div className="article-card-content">
                        <label>The journey of thousand miles begins with single steps.</label>
                        <div className="blogBtn">
                          <button>Read More</button>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* blog content end */}
        </div>
      {/* blog content end */}        
      <Footer/>
    </>
  );
}

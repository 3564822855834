import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import Carousel1 from 'react-elastic-carousel';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Item1 from './Item1';
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function ProjectsDetails({ category }) {
    useEffect(() => {
        document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
    }, []);
  
    const [fix, setFix] = useState(false);
    
    function setFixed() {
        if (window.scrollY >= 610) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);

    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: false });
        }
    };

    const [selects, setSelects] = useState("");
    const [credentials, setCredentials] = useState(
        {
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
        }
    );
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const xyz = () => {
        setCredentials(
            {
                fName: credentials.fName,
                lName: credentials.lName,
                mobile: credentials.mobile,
                email: credentials.email,
                city: selects,
                lookingFor: credentials.lookingFor,
            }
        )
    }
    const [alertInput, setAlertInput] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = async (e) => {
        e.preventDefault();
        if (credentials.fName === '') {
            setAlertInput(true)
        }if (credentials.lName === '') {
            setAlertInput(true)
        }if (credentials.mobile === '') {
            setAlertInput(true)
        }if (credentials.email === '') {
            setAlertInput(true)
        }if (credentials.city === '') {
            setAlertInput(true)
        }if (credentials.lookingFor === '') {
            setAlertInput(true)
        } else {
            setAlertInput(false);
            // const formData = new FormData(e.target);
            axios.post(`https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // http://localhost/api/post-endpoint
            .then(response => {
                setFormResponse(response.data.message);
                toast('Thank For Consult with us our executive will contact you soon🙂', {
                    // toast('🦄 Wow so easy!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                hideModal();
                setIsClicked(true);
                const elements = document.getElementsByClassName('modal-backdrop');    
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('new-class');
                    const divToRemove = elements[i];
                    divToRemove.remove();
                }
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
                setCredentials(
                    {
                        fName: "",
                        lName: "",
                        mobile: "",
                        email: "",
                        city: "",
                        lookingFor: "",
                    }
                )
            })
            .catch(error => {
                console.error(error);
                setFormResponse('Error sending OTP');
            });
        }
    }

    const hideModal = () => {
        // setTimeout(() => {
        //     window.location.href('/');
        // }, 1000);
    }

    const [fix1, setFix1] = useState(false);
    
    function setFixed1() {
        if (window.scrollY >= 200) {
            setFix1(true);
        } else {
            setFix1(false);
        }
    }
    window.addEventListener("scroll", setFixed1);
    
    const [hideBox, setHideBox] = useState(true);
    const hideBoxfunc = () => {
        setHideBox(false);
    }

    const breakPoints1 = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 3.5 },
        { width: 768, itemsToShow: 3.5 },
        { width: 1200, itemsToShow: 3.5 },
    ]
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const items = [
        {
            quote: "I am truly amazed by the level of craftsmanship and attention to detail that 99SquareWall brought to my home. From the initial design discussions to the final touches, their team demonstrated professionalism and dedication. The result is a living space that not only reflects my style but also exceeds my expectations. I can't thank them enough for turning my house into a place I'm proud to call home.",
            name: "Pritesh Mahajan",
            place: "Kothrud, Pune",
        },
        {
            quote: "Working with 99SquareWall was a fantastic experience. Their expertise in commercial construction is evident in the stunning space they delivered for my business. The entire process was seamless, from the creative design phase to the flawless execution. I appreciate their commitment to quality and their ability to meet deadlines. I highly recommend 99SquareWall to anyone seeking top-notch construction services.",
            name: "Suresh Bhujbal",
            place: "Hinjewadi, Pune",
        },
        {
            quote: "I had the pleasure of collaborating with 99SquareWall on a residential project, and I'm thrilled with the results. Their team took the time to understand my vision and translated it into a design that perfectly captures my style. The attention to detail, quality of materials, and craftsmanship are remarkable. 99SquareWall transformed my space into a functional and beautiful home.",
            name: "Rohini Tyagi",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "From the very beginning, 99SquareWall demonstrated a deep understanding of my aspirations for my new home. Their architects and builders worked in harmony, resulting in a seamless transformation. The dedication to achieving perfection and the precision in execution set them apart. The final result is a home that not only meets but surpasses my dreams.",
            name: "Rohit Mishra",
            place: "Punawale, Pune",
        },
        {
            quote: "I had the privilege of partnering with 99SquareWall on a commercial project, and their commitment to excellence is unparalleled. Their team combines aesthetics with functionality effortlessly. They transformed my vision into a space that captures the essence of my business. I am grateful for their professionalism, creativity, and dedication.",
            name: "Mahira Baig",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "My experience with 99SquareWall was exceptional. Their architects and designers truly understand how to harmonize beauty and practicality. From the initial consultation to the final walk-through, their attention to detail and dedication to quality were evident. They turned my vision into reality while ensuring functionality and aesthetics were perfectly balanced.",
            name: "Suman Kothari",
            place: "Talegaon Dabhade, Pune",
        },
    ];
  
    const handleTransitionEnd = () => {
        const { current } = carouselRef;
        if (current) {
            const newIndex = current.getActiveIndex();
            setCurrentIndex(newIndex);
            
            // Manually reset the carousel to the beginning when it reaches the end
            if (newIndex === items.length - 1) {
                current.goTo(0);
            }
        }
    };

  return (
    <>
        <Header />

        <div
            className="project-details"
        >
            <div className="project-container">
                <div>
                    <p style={{ width: '15rem', borderLeft: '3px solid', paddingLeft: '9px', }}>Redesigning Asian Paints</p>
                    {/* <span
                        style={{
                            color: '#ffffff',
                            fontSize: '16px',
                            margin: '0 auto',
                            textAlign: 'center',
                            width: '46rem',
                            display: 'block',
                        }}
                    >
                        Discover our Home Construction Services, where ideas become abodes. With expertise in every detail, we craft homes that inspire and endure. From design to delivery, trust us to build the haven you envision.
                    </span> */}
                </div>
                <img
                    src="/example-img/project-img.jpg"
                    alt=""
                />
            </div>
        </div>

        <div className="main project-details-main">
            <div id="overviewDetails" className="tfp-12c0fe6">
                <div className="tfp-0">
                    <div data-q="more-about-project" className="tfp-fq1k4c">
                        <h2 className="tfp-1n0yqli"> Overview</h2>
                        <table className="tfp-13o7eu2">
                            <tbody className="tfp-1mkc5st">
                                <tr className="data-point tfp-1dw01hu">
                                    <th className="tfp-2fe3eo">Project Area</th>
                                    <td className="tfp-m5gngy">
                                        <div className="tfp-g89h0y">landparcel Acres</div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-1lcoo1d">
                                    <th className="tfp-2fe3eo">Sizes</th>
                                    <td className="tfp-m5gngy" style={{ display: '-webkit-box', }}>
                                        <div className="tfp-g89h0y">1,350 sq.ft.- </div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-1xsqnfd">
                                    <th className="tfp-2fe3eo">Project Size</th>
                                    <td className="tfp-m5gngy">
                                        <div className="tfp-g89h0y">6 Buildings - 100 units</div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-1l1ymfu">
                                    <th className="tfp-2fe3eo">Launch Date</th>
                                    <td className="tfp-m5gngy">
                                        <div className="tfp-g89h0y">22-10-2025</div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-1rc980e">
                                    <th className="tfp-2fe3eo">Starting Price</th>
                                    <td className="tfp-m5gngy">
                                        <div className="tfp-g89h0y">
                                            <span className="rupee-icon tfp-1cqirzg">₹ </span>1 CR
                                        </div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-18jdtc4">
                                    <th className="tfp-2fe3eo">Possession Starts</th>
                                    <td className="tfp-m5gngy">
                                        <div className="tfp-g89h0y">24 Nov 2025</div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-78vvkv">
                                    <th className="tfp-2fe3eo">Configurations</th>
                                    <td className="tfp-m5gngy" style={{ flexFlow: 'revert', }}>
                                        <div className="tfp-g89h0y">2,3,4,5 BHK Apartments</div>
                                    </td>
                                </tr>
                                <tr className="data-point tfp-1w61pq1">
                                    <th className="tfp-2fe3eo">Rera Id</th>
                                    <td className="tfp-m5gngy">
                                        <a href="" className="tfp-1840t66">dfgdfgsdfsdfsdgsdfsdf</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="tfp-1w1q62f">
                            <div className="tfp-qyftnl"><i className="fa-solid fa-share"></i> Share</div>
                            <div className="tfp-ysdrrh">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path style={{fill: '#d8232a'}} d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/>
                                </svg>
                                <span className="tfp-1mnhdci"><i className="fa-solid fa-heart"></i> Shortlist</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        
        <Footer/>

    </>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import Carousel1 from 'react-elastic-carousel';
import Item from './Item';
import Item1 from './Item1';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { Player, ControlBar, PlayToggle, PosterImage, VolumeMenuButton, FullscreenToggle, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton } from 'video-react';
import 'video-react/dist/video-react.css';
import Header from "./Header.js";
import Footer from "./Footer.js";

const breakPoints1 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3.5 },
    { width: 768, itemsToShow: 3.5 },
    { width: 1200, itemsToShow: 3.5 },
]

export default function Renovation({ category }) {
    useEffect(() => {
        document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
    }, []);
  

    const [fix, setFix] = useState(false);
    
    function setFixed() {
        if (window.scrollY >= 610) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);

    const [fix1, setFix1] = useState(false);
    
    function setFixed1() {
        if (window.scrollY >= 200) {
            setFix1(true);
        } else {
            setFix1(false);
        }
    }
    window.addEventListener("scroll", setFixed1);
    
    const [hideBox, setHideBox] = useState(true);
    const hideBoxfunc = () => {
        setHideBox(false);
    }
    const [credentials, setCredentials] = useState(
        {
            fullName: "",
            email: "",
        }
    );
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const [mobile, setMobile] = useState('');
    const [pincode, setPincode] = useState('');
    const [whatsappUpdate, setWhatsappUpdate] = useState('off');
    const [constructionUpdate, setConstructionUpdate] = useState('no');

    const handleWhatsappUpdate = () => {
        if (whatsappUpdate === 'off') {
            setWhatsappUpdate('on');
        } else {
            setWhatsappUpdate('off');
        }
    }
    const handleConstructionUpdateYes = () => {
        setConstructionUpdate('yes');
    }
    const handleConstructionUpdateNo = () => {
        setConstructionUpdate('no');
    }

    const handleMobile = (event) => {
        // Get the input value and ensure it doesn't exceed 5 characters
        let inputValue = event.target.value;
        if (inputValue.length <= 10) {
            setMobile(inputValue);
        }
    };
    const handlePincode = (event) => {
        // Get the input value and ensure it doesn't exceed 5 characters
        let inputValue = event.target.value;
        if (inputValue.length <= 6) {
            setPincode(inputValue);
        }
    };
    const [alertInput, setAlertInput] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const handleIsClickedTrue = () => {
        setIsClicked(true);
    }
    const handleClose = async (e) => {
        setIsClicked(false);
    }
    const handleClick = async (e) => {
        e.preventDefault();
        if (credentials.fullName === '') {
            setAlertInput(true)
        }if (credentials.email === '') {
            setAlertInput(true)
        }if (mobile === '') {
            setAlertInput(true)
        }if (pincode === '') {
            setAlertInput(true)
        } else {
            setAlertInput(false);
            // const formData = new FormData(e.target);
            axios.post(`https://www.99squarewall.com/consultation.php?fullName=${credentials.fullName}&email=${credentials.email}&mobile=${mobile}&pincode=${pincode}&whatsappUpdate=${whatsappUpdate}&constructionUpdate=${constructionUpdate}`)
            // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // http://localhost/api/post-endpoint
            .then(response => {
                setFormResponse(response.data.message);
                // setShowOTP(true);
                // toast('Thank For Consult with us our executive will contact you soon🙂');
                toast('Thank For Consult with us our executive will contact you soon🙂', {
                    // toast('🦄 Wow so easy!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setIsClicked(true);
                const elements = document.getElementsByClassName('modal-backdrop');    
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('new-class');
                    const divToRemove = elements[i];
                    divToRemove.remove();
                }
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
            })
            .catch(error => {
                console.error(error);
                setFormResponse('Error sending OTP');
            });
        }
        
    }
    
    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: false });
        }
    };

    

    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const items = [
        {
            quote: "I am truly amazed by the level of craftsmanship and attention to detail that 99SquareWall brought to my home. From the initial design discussions to the final touches, their team demonstrated professionalism and dedication. The result is a living space that not only reflects my style but also exceeds my expectations. I can't thank them enough for turning my house into a place I'm proud to call home.",
            name: "Pritesh Mahajan",
            place: "Kothrud, Pune",
        },
        {
            quote: "Working with 99SquareWall was a fantastic experience. Their expertise in commercial construction is evident in the stunning space they delivered for my business. The entire process was seamless, from the creative design phase to the flawless execution. I appreciate their commitment to quality and their ability to meet deadlines. I highly recommend 99SquareWall to anyone seeking top-notch construction services.",
            name: "Suresh Bhujbal",
            place: "Hinjewadi, Pune",
        },
        {
            quote: "I had the pleasure of collaborating with 99SquareWall on a residential project, and I'm thrilled with the results. Their team took the time to understand my vision and translated it into a design that perfectly captures my style. The attention to detail, quality of materials, and craftsmanship are remarkable. 99SquareWall transformed my space into a functional and beautiful home.",
            name: "Rohini Tyagi",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "From the very beginning, 99SquareWall demonstrated a deep understanding of my aspirations for my new home. Their architects and builders worked in harmony, resulting in a seamless transformation. The dedication to achieving perfection and the precision in execution set them apart. The final result is a home that not only meets but surpasses my dreams.",
            name: "Rohit Mishra",
            place: "Punawale, Pune",
        },
        {
            quote: "I had the privilege of partnering with 99SquareWall on a commercial project, and their commitment to excellence is unparalleled. Their team combines aesthetics with functionality effortlessly. They transformed my vision into a space that captures the essence of my business. I am grateful for their professionalism, creativity, and dedication.",
            name: "Mahira Baig",
            place: "Koregaon Park, Pune",
        },
        {
            quote: "My experience with 99SquareWall was exceptional. Their architects and designers truly understand how to harmonize beauty and practicality. From the initial consultation to the final walk-through, their attention to detail and dedication to quality were evident. They turned my vision into reality while ensuring functionality and aesthetics were perfectly balanced.",
            name: "Suman Kothari",
            place: "Talegaon Dabhade, Pune",
        },
    ];
  
    const handleTransitionEnd = () => {
        const { current } = carouselRef;
        if (current) {
            const newIndex = current.getActiveIndex();
            setCurrentIndex(newIndex);
            
            // Manually reset the carousel to the beginning when it reaches the end
            if (newIndex === items.length - 1) {
                current.goTo(0);
            }
        }
    };

    return (
        <>
            <Header />

            {/* --========== Hero Section Start ==========-- */}
                <div className="home-construction-hero">
                    <div className="container">
                        <div className="left">
                            <div className="leftText" style={{ width: '33rem', }}>
                                <div>
                                    <p>Crafting Dreams into Reality: Our Home Construction Services</p>
                                    <span>
                                        Discover our Home Construction Services, where ideas become abodes. With expertise in every detail, we craft homes that inspire and endure. From design to delivery, trust us to build the haven you envision.
                                    </span>
                                </div>
                                <button onClick={openCalendlyPopup}>Schedule A Call</button>
                            </div>
                        </div>
                        <div className="right">
                            <div className="">
                                <div className="modal-bg2">
                                    <div className="modal-form">
                                        <div className="modal-bottom">
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="fullName" className="form-label">Name*</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullName"
                                                        name="fullName"
                                                        placeholder="Your Full Name"
                                                        value={credentials.fullName}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email*</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Eg: myname@gmail.com"
                                                        value={credentials.email}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                <div className="row align-items-center mb-3">
                                                    <div className="col-auto mobile">
                                                        <label htmlFor="mobile" className="form-label">Mobile Number*</label>
                                                        <div className="mobile-container">
                                                            <img src="/img/india.png" />
                                                            <p>+91</p>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            className="form-control2"
                                                            id="mobile"
                                                            name="mobile"
                                                            placeholder="10 digits"
                                                            value={mobile}
                                                            onChange={handleMobile}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="pincode" className="form-label">Pin Code*</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="pincode"
                                                            name="pincode"
                                                            placeholder="6 digit pin code"
                                                            value={pincode}
                                                            onChange={handlePincode}
                                                        />
                                                    </div>
                                                </div>
                                                {/* {location.pathname === '/' &&(
                                                    <>
                                                        Home
                                                    </>
                                                )}
                                                {location.pathname === '/homeConstruction' &&(
                                                    <>
                                                        homeConstruction
                                                    </>
                                                )}
                                                {location.pathname === '/commercialBuilding' &&(
                                                    <>
                                                        commercialBuilding
                                                    </>
                                                )}
                                                {location.pathname === '/interior' &&(
                                                    <>
                                                        interior
                                                    </>
                                                )}
                                                {location.pathname === '/renovation' &&(
                                                    <>
                                                        renovation
                                                    </>
                                                )} */}
                                                <div className="whatsapp-update mb-3">
                                                    <label htmlFor="fullName" className="form-label mb-3">Update me on WhatsApp</label>
                                                    <button type="button" onClick={handleWhatsappUpdate}>
                                                        <img src="/img/whatsapp1.png" alt="" />
                                                        <p className={whatsappUpdate === 'off' ? '' : 'on'} >{whatsappUpdate === 'off' ? 'off' : 'on'}</p>
                                                    </button>
                                                </div>
                                                <div className="any-construction">
                                                    <p>Is any construction work going on at your house ?</p>
                                                    <div className="yes-no-button">
                                                        <button type="button" className={constructionUpdate === 'yes' ? "button active" : "button"} onClick={handleConstructionUpdateYes}>
                                                            yes
                                                        </button>
                                                        <button type="button" className={constructionUpdate === 'no' ? "button active" : "button"} onClick={handleConstructionUpdateNo}>
                                                            no
                                                        </button>
                                                    </div>
                                                </div>
                                                <button type="button" className="final-button" onClick={handleClick}>
                                                    Book Free Consultation
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* --========== Hero Section Start ==========-- */}

            <div className="main">

                {/* --========== commercial construction type Start ==========-- */}
                    <section className="commercial-construction-type">
                        <div className="container ">
                            <div className="section-title" style={{ paddingBottom: '0px', }}>
                                <p>Elevate <font color="#ed1c24">Your Living</font></p>
                                <span>Seamless excellence from concept to creation. Our comprehensive turnkey services encompass design, planning, construction, and finishing touches. Experience a streamlined journey as we transform your vision into a fully realized masterpiece.</span>
                            </div>
                            <div className="list">
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                                <div className="list1">
                                    <a href="" target="_blank">
                                        <div>
                                            <h3>Home Loan<em className="icon-arrow-right"></em></h3>
                                            <p>Get Instant Sanction on your Home Loan in 5 minutes</p>
                                        </div>
                                        <figure className="homeLoanBg">
                                            <img className="img-responsive" src="https://www.squareyards.com/assets/images/dotcom-services-icons/home-loan-mobile.svg" alt="Home Loan" />
                                        </figure>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                {/* --========== commercial construction type End ==========-- */}

                {/* --========== why choose us Start ==========-- */}
                    <div className="founder-info-section" style={{ alignItems: 'center', }}>
                        <div className="founder-img">
                            <img src="/img/home-construction.jpg" alt="" />
                        </div>
                        <div className="right-founder">
                            <h3>Our Exceptional Home Construction Services</h3>
                            <p>
                                At 99SquareWall, we specialize in transforming your aspirations into tangible realities. Our comprehensive range of home construction services encompasses the essence of personalized living. With an unwavering commitment to excellence, innovation, and sustainability, we take pride in creating homes that stand as true reflections of your lifestyle.
                                <br />
                                <br />
                                As you embark on the journey of home construction with 99SquareWall, you're not just building a house – you're crafting a future where luxury, innovation, and sustainability converge seamlessly. Let us be your trusted partner in this transformative endeavor.
                                <br />
                            </p>
                        </div>
                    </div>
                {/* --========== why choose us End ==========-- */}

                {/* --========== commercial Construction pricing Section Start ==========-- */}
                    <section className="pricing">
                        <div className="container howitwork-container">
                            <div className="section-title" style={{ paddingBottom: '0px', }}>
                                <p>Select From <font color="#ed1c24">Our Packages</font></p>
                                <span>Select from our packages.</span>
                            </div>
                            <section className="pricing-section">
                                <div className="pricing-1">
                                    <h3 className="heading">Elegant Essence Package</h3>
                                    <p className="subHeading">Crafted sophistication for your dream space.</p>
                                    <div className="price">
                                        <p>₹</p>
                                        <span>1800</span>
                                        <p>/sq.ft </p>
                                        <p className="gst">(Incl GST)</p>
                                    </div>
                                    <p className="duration">+3 months FREE</p>
                                    <div className="line" />
                                    
                                    {/* accordion */}
                                        <div id="accordion" style={{ width: '100%', }}>
                                            <div className="card">
                                                <div className="card-header" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Designs & Drawings
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li>
                                                            <li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li>
                                                            <li className="list-item">
                                                                Basic Elevation
                                                            </li>
                                                            <li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Structure
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">Brickwork-6" Red  Bricks for both Internal and External Walls</li>
                                                            <li className="list-item">Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</li>
                                                            <li className="list-item">Cement - JK,Ambuja,Chettinad or equivalent 43 or 53 grade</li>
                                                            <li className="list-item">Steel - Rajuri, Kalika ,Kamdhenu or equivalent</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            Kitchen
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">Any other Faucet or Accessories - ISI Marked</li>
                                                            <li className="list-item">Ceramic Wall Dado - Upto Rs.40 per Sqft</li>
                                                            <li className="list-item">Kitchen Sink - Stainless Steel of Single Sink make worth Rs. 3,000</li>
                                                            <li className="list-item">Main Sink Faucet - Upto Rs.1300</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* accordion */}

                                </div>
                                <div className="pricing-1">
                                    <h3 className="heading">Luxury Haven Package</h3>
                                    <p className="subHeading">Opulent living, refined aesthetics, and unmatched comfort.</p>
                                    <div className="price">
                                        <p>₹</p>
                                        <span>2000</span>
                                        <p>/sq.ft </p>
                                        <p className="gst">(Incl GST)</p>
                                    </div>
                                    <p className="duration">+3 months FREE</p>
                                    <div className="line" />
                                    
                                    {/* accordion */}
                                        <div id="accordion" style={{ width: '100%', }}>
                                            <div className="card">
                                                <div className="card-header" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                            Collapsible Group Item #1
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div id="collapseFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            Collapsible Group Item #2
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseFive" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            Collapsible Group Item #3
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseSix" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* accordion */}

                                </div>
                                <div className="pricing-1">
                                    <h3 className="heading">Signature Serenity Package</h3>
                                    <p className="subHeading">Unparalleled luxury, comfort, and style combined seamlessly.</p>
                                    <div className="price">
                                        <p>₹</p>
                                        <span>2500</span>
                                        <p>/sq.ft </p>
                                        <p className="gst">(Incl GST)</p>
                                    </div>
                                    <p className="duration">+3 months FREE</p>
                                    <div className="line" />
                                    
                                    {/* accordion */}
                                        <div id="accordion" style={{ width: '100%', }}>
                                            <div className="card">
                                                <div className="card-header" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                                            Collapsible Group Item #1
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div id="collapseSeven" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                            Collapsible Group Item #2
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseEight" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                            Collapsible Group Item #3
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path style={{ fill: '#000000', width: '9px', height: '23px', }} d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                                            </svg>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseNine" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <ul className="list">
                                                            <li className="list-item">
                                                                3D Elevation
                                                            </li><li className="list-item">
                                                                Architectural Layout | 2D
                                                            </li><li className="list-item">
                                                                Basic Elevation
                                                            </li><li className="list-item">
                                                                Structural Design
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* accordion */}

                                </div>
                            </section>
                        </div>
                    </section>
                {/* --========== commercial Construction pricing Section End ==========-- */}

            </div>

            {/* --========== Testimonials Start ==========-- */}
                <section id="testimonials" className="testimonials">
                    <div className="container testimonialcontainer">
                        <div className="section-title">
                            <p>What they are<font color="#ed1c24"> saying about</font> us</p>
                        </div>
                        <div className="mb">
                            <Carousel1
                                ref={carouselRef}
                                onTransitionEnd={handleTransitionEnd}
                                breakPoints={breakPoints1}
                                pagination={false}
                                showArrows={false}
                                enableSwipe={true}
                                enableAutoPlay={false}
                                autoPlaySpeed={3000}
                                onNextStart={true}
                            >
                                {items.map((item, index) => (
                                    <Item1>
                                        <div tabIndex="-1" className="sc-qRumB hPSzWg">
                                            <div className="testimonial-item">
                                                <p><span>"</span>{item.quote}<span>"</span></p>
                                                <h3>{item.name}</h3>
                                                <h4>{item.place}</h4>
                                            </div>
                                        </div>
                                    </Item1>
                                ))}
                            </Carousel1>
                        </div>
                    </div>
                </section>
            {/* --========== Testimonials End ==========-- */}
            
            <Footer/>
        </>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import Carousel1 from 'react-elastic-carousel';
import Item from './Item';
import Item1 from './Item1';
import Item2 from './Item2';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { Player, ControlBar, PlayToggle, PosterImage, VolumeMenuButton, FullscreenToggle, CurrentTimeDisplay, TimeDivider, PlaybackRateMenuButton } from 'video-react';
import 'video-react/dist/video-react.css';
import Header from "./Header.js";
import Footer from "./Footer.js";

const breakPoints1 = [
    { width: 1, itemsToShow: 1.1 },
    { width: 550, itemsToShow: 1.1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
]
const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 6 },
    { width: 768, itemsToShow: 6 },
    { width: 1200, itemsToShow: 6 },
]

export default function Interior({ category }) {
    useEffect(() => {
        document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
    }, []);
  

    const [fix, setFix] = useState(false);
    
    function setFixed() {
        if (window.scrollY >= 610) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);

    const [fix1, setFix1] = useState(false);
    
    function setFixed1() {
        if (window.scrollY >= 200) {
            setFix1(true);
        } else {
            setFix1(false);
        }
    }
    window.addEventListener("scroll", setFixed1);
    
    const [hideBox, setHideBox] = useState(true);
    const hideBoxfunc = () => {
        setHideBox(false);
    }

    const [selects, setSelects] = useState("");
    const [credentials, setCredentials] = useState(
        {
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
        }
    );
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const xyz = () => {
        setCredentials(
            {
                fName: credentials.fName,
                lName: credentials.lName,
                mobile: credentials.mobile,
                email: credentials.email,
                city: selects,
                lookingFor: credentials.lookingFor,
            }
        )
    }
    const [alertInput, setAlertInput] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = async (e) => {
        e.preventDefault();
        if (credentials.fName === '') {
            setAlertInput(true)
        }if (credentials.lName === '') {
            setAlertInput(true)
        }if (credentials.mobile === '') {
            setAlertInput(true)
        }if (credentials.email === '') {
            setAlertInput(true)
        }if (credentials.city === '') {
            setAlertInput(true)
        }if (credentials.lookingFor === '') {
            setAlertInput(true)
        } else {
            setAlertInput(false);
            // const formData = new FormData(e.target);
            axios.post(`https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // http://localhost/api/post-endpoint
            .then(response => {
                setFormResponse(response.data.message);
                // setShowOTP(true);
                // toast('Thank For Consult with us our executive will contact you soon🙂');
                toast('Thank For Consult with us our executive will contact you soon🙂', {
                    // toast('🦄 Wow so easy!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setIsClicked(true);
                const elements = document.getElementsByClassName('modal-backdrop');    
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('new-class');
                    const divToRemove = elements[i];
                    divToRemove.remove();
                }
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
            })
            .catch(error => {
                console.error(error);
                setFormResponse('Error sending OTP');
            });
        }
    }
    
    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: false });
        }
    };    

    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const items = [
        {
            image: "/interior-services/1.jpg",
            heading: "Modular Interiors",
            subHeading: "Functional kitchen, wardrobe and storage",
            backgroundColor: "#b1fff1",
            color1: "#009177",
            color2: "#00b999",
        },
        {
            image: "/interior-services/2.jpg",
            heading: "Full Home Interiors",
            subHeading: "Turnkey interior solutions for your home",
            backgroundColor: "#ffd894",
            color1: "#a76a00",
            color2: "#e59200",
        },
        {
            image: "/interior-services/1.jpg",
            heading: "Luxury Interiors",
            subHeading: "Tailored  interiors that redefine elegance",
            backgroundColor: "#ffd0b5",
            color1: "#f75f09",
            color2: "#e15100",
        },
        {
            image: "/interior-services/1.jpg",
            heading: "Renovations",
            subHeading: "Expert solutions to upgrade your home",
            backgroundColor: "#fde1ff",
            color1: "#f558ff",
            color2: "#f558ff",
        },
        {
            image: "/interior-services/1.jpg",
            heading: "Commercial Interiors",
            subHeading: "Hassle-free solutions for your workspace",
            backgroundColor: "#cee7ff",
            color1: "#007fff",
            color2: "#0070e1",
        },
        {
            image: "/interior-services/1.jpg",
            heading: "Furniture & decor",
            subHeading: "Exceptional products to amp up your space",
            backgroundColor: "#ece7ff",
            color1: "#744aff",
            color2: "#5522ff",
        }
    ];
  
    const handleTransitionEnd = () => {
        const { current } = carouselRef;
        if (current) {
            const newIndex = current.getActiveIndex();
            setCurrentIndex(newIndex);
            
            // Manually reset the carousel to the beginning when it reaches the end
            if (newIndex === items.length - 1) {
                current.goTo(0);
            }
        }
    };

    const [stepActive, setStepActive] = useState('1');
    // alert('1');

    if (stepActive === '1') {
        setTimeout(() => {
            setStepActive('2');
        }, 4000);
    }
    if (stepActive === '2') {
        setTimeout(() => {
            setStepActive('3');
        }, 4000);
    }
    if (stepActive === '3') {
        setTimeout(() => {
            setStepActive('4');
        }, 4000);
    }
    if (stepActive === '4') {
        setTimeout(() => {
            setStepActive('5');
        }, 4000);
    }
    if (stepActive === '5') {
        setTimeout(() => {
            setStepActive('1');
        }, 4000);
    }

    return (
        <>
            <Header />

            {/* --========== Hero Section Start ==========-- */}
                <div className="home-construction-hero xyz">
                    <div className="container">
                        <div className="left">
                            <div className="leftText" style={{ width: '33rem', }}>
                                {/* <div>
                                    <p>Crafting Dreams into Reality: Our Home Construction Services</p>
                                    <span>
                                        Discover our Home Construction Services, where ideas become abodes. With expertise in every detail, we craft homes that inspire and endure. From design to delivery, trust us to build the haven you envision.
                                    </span>
                                </div>
                                <button onClick={openCalendlyPopup}>Schedule A Call</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            {/* --========== Hero Section Start ==========-- */}

            <div className="main">

                <section id="pricing" className="pricing">
                    <div className="container howitwork-container">
                        <div className="section-title">
                            <p>One-stop shop for <font color="#ed1c24">all things interiors</font></p>
                            <span>Discover the 99SquareWall advantage. Four pillars that make us the preferred choice in construction</span>
                        </div>
                        <div className="interior-services-list">
                            <div className="interior-services-box">
                                {/* <div className="box" style={{ backgroundColor: '#b1fff1' }}>
                                    <div className="OurService_serviceDetails__kFk1_">
                                        <label className="OurService_text__W4XpJ text222 f500" style={{ color: '#00a588' }}>Home Construction Services</label>
                                        <p className="OurService_subText__2in_7  font14 text666" style={{ color: '#00b999' }}>
                                            Crafting dream homes with innovative design, tailored to your style, turning aspirations into reality.
                                        </p>
                                    </div>
                                    <div className="services-img">
                                        <img src="/interior-services/1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="box" style={{ backgroundColor: '#ffd894' }}>
                                    <div className="OurService_serviceDetails__kFk1_">
                                        <label className="OurService_text__W4XpJ text222 f500" style={{ color: '#e79300' }}>Home Construction Services</label>
                                        <p className="OurService_subText__2in_7  font14 text666" style={{ color: '#e59200' }}>
                                            Crafting dream homes with innovative design, tailored to your style, turning aspirations into reality.
                                        </p>
                                    </div>
                                    <div className="services-img">
                                        <img src="/interior-services/2.jpg" alt="" />
                                    </div>
                                </div> */}

                                <Carousel
                                    ref={carouselRef}
                                    onTransitionEnd={handleTransitionEnd}
                                    breakPoints={breakPoints1}
                                    pagination={false}
                                    showArrows={false}
                                    enableSwipe={true}
                                    enableAutoPlay={false}
                                    autoPlaySpeed={3000}
                                >
                                    {items.map((item, index) => (
                                        <Item2>
                                            <div className="box" style={{ backgroundColor: item.backgroundColor }}>
                                                <div className="OurService_serviceDetails__kFk1_">
                                                    <label className="OurService_text__W4XpJ text222 f500" style={{ color: item.color1 }}>{item.heading}</label>
                                                    <p className="OurService_subText__2in_7  font14 text666" style={{ color: item.color2 }}>
                                                        {item.subHeading}
                                                    </p>
                                                </div>
                                                <div className="services-img">
                                                    <img src={item.image} alt="" />
                                                </div>
                                            </div>
                                        </Item2>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                {/* --========== How It Work Start ==========-- */}
                    <section id="pricing" className="pricing howItWork" style={{  backgroundColor: '#fff' }}>
                        <div className="container howitwork-container">
                            <div className="section-title">
                                <p>How it <font color="#ed1c24">works</font></p>
                                <span>From dream to reality in five simple steps. Experience the journey of construction excellence with us.</span>
                            </div>
                            <div className="step-by-step-how-it-work">
                                <div className="img">
                                    {stepActive === '1' ? (
                                        <>
                                            <img src="/img/howItWork/1consultant.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    {stepActive === '2' ? (
                                        <>
                                            <img src="/img/howItWork/2designAndPlanning.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    {stepActive === '3' ? (
                                        <>
                                            <img src="/img/howItWork/3permitsAndApproval.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    {stepActive === '4' ? (
                                        <>
                                            <img src="/img/howItWork/4construction.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                    {stepActive === '5' ? (
                                        <>
                                            <img src="/img/howItWork/5completionAndHandover-removebg-preview.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                                <div className="">
                                    <ul className="HowItWorks_stepsBx__HY4oU">
                                        {stepActive === '1' ? (
                                            <>
                                                <li className="HowItWorks_active__4JMUw">
                                                    <span>1</span>
                                                    <label>Consultation</label>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="">
                                                    <span>1</span>
                                                    <label>Consultation</label>
                                                </li>
                                            </>
                                        )}
                                        {stepActive === '2' ? (
                                            <>
                                                <li className="HowItWorks_active__4JMUw">
                                                    <span>2</span>
                                                    <label>Desgin & Plan</label>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="">
                                                    <span>2</span>
                                                    <label>Desgin & Plan</label>
                                                </li>
                                            </>
                                        )}
                                        {stepActive === '3' ? (
                                            <>
                                                <li className="HowItWorks_active__4JMUw">
                                                    <span>3</span>
                                                    <label>Permit & Approvals</label>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="">
                                                    <span>3</span>
                                                    <label>Permit & Approvals</label>
                                                </li>
                                            </>
                                        )}
                                        {stepActive === '4' ? (
                                            <>
                                                <li className="HowItWorks_active__4JMUw">
                                                    <span>4</span>
                                                    <label>Construction</label>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="">
                                                    <span>4</span>
                                                    <label>Construction</label>
                                                </li>
                                            </>
                                        )}
                                        {stepActive === '5' ? (
                                            <>
                                                <li className="HowItWorks_active__4JMUw">
                                                    <span>5</span>
                                                    <label>Completion & Handover</label>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li className="">
                                                    <span>5</span>
                                                    <label>Completion & Handover</label>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                    <ul className="HowItWorks_context__T0cn9">
                                        {stepActive === '1' ? (
                                            <>
                                                <li className="">
                                                    <label>Book A Consultation</label>
                                                    <p>
                                                        Begin your journey by sharing your vision with us. Our experts are ready to listen, understand, and provide insights tailored to your needs. We'll explore the possibilities together, ensuring a strong foundation for your project.
                                                    </p>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                        {stepActive === '2' ? (
                                            <>
                                                <li className="">
                                                    <label>Design and Planning</label>
                                                    <p>
                                                        Watch your ideas transform into tangible plans. Our seasoned architects and designers will craft detailed blueprints, aligning creativity with practicality. The result? A roadmap that brings your vision closer to reality.
                                                    </p>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                        {stepActive === '3' ? (
                                            <>
                                                <li className="">
                                                    <label>Permits and Approvals</label>
                                                    <p>
                                                        Navigating the complex landscape of permits and approvals is our expertise. We handle the paperwork and liaise with authorities, ensuring your project complies with regulations. Your peace of mind is our priority.
                                                    </p>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                        {stepActive === '4' ? (
                                            <>
                                                <li className="">
                                                    <label>Construction</label>
                                                    <p>
                                                        From groundbreaking to finishing touches, witness your dreams taking shape. Our skilled craftsmen, guided by a commitment to excellence, turn designs into structures. Experience each phase with confidence.
                                                    </p>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                        {stepActive === '5' ? (
                                            <>
                                                <li className="">
                                                    <label>Completion and Handover</label>
                                                    <p>
                                                        The moment of fulfillment arrives as we hand over your completed project. Our attention to detail and dedication to quality ensure that your vision is not only met but exceeded. Welcome to the realization of your dreams.
                                                    </p>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                {/* --========== How It Work End ==========-- */}

                <section id="pricing" className="pricing howItWork" style={{  backgroundColor: '#fff' }}>
                    <div className="container howitwork-container">
                        <div className="section-title">
                            <p>How it <font color="#ed1c24">works</font></p>
                            <span>From dream to reality in five simple steps. Experience the journey of construction excellence with us.</span>
                        </div>
                        <div className="about-four-box">
                            <div style={{ display: 'flex', }}>
                                <div className="box-1">
                                    <img src="/img/empowering.png" alt="mission" />
                                    <h3>Empowering Possibilities</h3>
                                    <p>Unveiling potential through transformative spaces, guiding our clients' visions into reality while setting new standards for innovation, sustainability, and design.</p>
                                </div>
                                <div className="box-1">
                                    <img src="/img/crafting.png" alt="mission" />
                                    <h3>Crafting Inspired Environments</h3>
                                    <p>Dedicated to shaping environments that inspire, enrich, and endure. Our mission is to create spaces that mirror aspirations, fostering growth and lasting memories.</p>
                                </div>
                                <div className="box-1">
                                    <img src="/img/integrity.png" alt="mission" />
                                    <h3>Integrity Drives Excellence</h3>
                                    <p>We stand by unwavering integrity in every endeavor. Excellence is our hallmark – from design to execution, transparency, and building lasting partnerships.</p>
                                </div>
                                <div className="box-1">
                                    <img src="/img/teamwork.png" alt="mission" />
                                    <h3>Teamwork Shapes Success</h3>
                                    <p>Collaboration is key. Our diverse team thrives on shared expertise, collective creativity, and a dedication to creating outcomes that inspire and endure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>

            <div className="consultation-container">
                <div className="container">
                    <img src="/img/consultation.jpg" alt="sdf" style={{ width: '50%', height: '21rem', }} />
                    <div className="div">
                        <h3>Home Interiors</h3>
                        <div className="p">
                            <p>Exceptional designs that cater to your aesthetic, functional and aspirational goals.</p>
                            <button className="header-btn">
                                <a className="nav-btn" target="_blank" style={{ color: 'black', }}>Schedule A Call</a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* --========== Brand For You Start ==========-- */}
                <div id="clients" className="clients" style={{ marginTop: '25px' }}>
                    <div className="container clientcontainer">
                        <div className="section-title">
                            <p>THE<font color="#ed1c24"> BEST BRANDS</font> FOR YOU</p>
                            <span>We carefully select our partners based on their reputation for quality, innovation, and value. Some of the brands we work with </span>
                        </div>
                        <div style={{ marginTop: '-4rem' }}>
                            <Carousel
                                breakPoints={breakPoints}
                                pagination={false}
                                showArrows={true}
                                enableSwipe={true}
                                enableAutoPlay={true}
                                autoPlaySpeed={3000}
                            >
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand1.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand2.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand3.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand4.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand5.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand6.png" alt="" width="200rem" className="img-fluid" naptha_cursor="text" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand7.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="0" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand8.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand9.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand10.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand11.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand12.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand13.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand14.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand15.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand16.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand17.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand18.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                                <Item>
                                    <div tabIndex="-1" className="sc-fsQiph XxSAY">
                                        <img src="/img/clients/brand19.png" alt="" width="200rem" className="img-fluid" />
                                    </div>
                                </Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            {/* --========== Brand For You End ==========-- */}
                 
            <Footer/>
        </>
    )
}
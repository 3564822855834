import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-elastic-carousel";
import Carousel1 from "react-elastic-carousel";
import Item from "./Item";
import Item1 from "./Item1";
import Item3 from "./Item3";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
  Player,
  ControlBar,
  PlayToggle,
  PosterImage,
  VolumeMenuButton,
  FullscreenToggle,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
import Header from "./Header.js";
import Footer from "./Footer.js";

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 6 },
  { width: 768, itemsToShow: 6 },
  { width: 1200, itemsToShow: 6 },
];
const breakPoints1 = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3.5 },
  { width: 768, itemsToShow: 3.5 },
  { width: 1200, itemsToShow: 3.5 },
];
const breakPoints2 = [
  { width: 1, itemsToShow: 1.1 },
  { width: 414, itemsToShow: 1.2 },
  { width: 550, itemsToShow: 1.5 },
  { width: 768, itemsToShow: 3.5 },
  { width: 1200, itemsToShow: 3.5 },
];

export default function Home({ category }) {
  // useEffect(() => {
  //   document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
  // }, []);

  const [selects, setSelects] = useState("");
  const [credentials, setCredentials] = useState({
    fullName: "",
    email: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [whatsappUpdate, setWhatsappUpdate] = useState("on");
  const [constructionUpdate, setConstructionUpdate] = useState("yes");

  const handleWhatsappUpdate = () => {
    if (whatsappUpdate === "off") {
      setWhatsappUpdate("on");
    } else {
      setWhatsappUpdate("off");
    }
  };
  const handleConstructionUpdateYes = () => {
    setConstructionUpdate("yes");
  };
  const handleConstructionUpdateNo = () => {
    setConstructionUpdate("no");
  };

  const handleMobile = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 10) {
      setMobile(inputValue);
    }
  };
  // Handler to allow only numeric input
  const handleInputChange = (e) => {
    const value = e.target.value;
    let inputValue = e.target.value;
    if (inputValue.length <= 10) {
      // Allow only numeric values
      if (!isNaN(value) && !value.includes('e') && !value.includes('E') && !value.includes('+') && !value.includes('-')) {
        setMobile(value);
      }
    }
  };
  // Prevent default behavior for non-numeric keys
  const handleKeyDown = (e) => {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };
  const handlePincode = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 6) {
      setPincode(inputValue);
    }
  };
  const [alertInput, setAlertInput] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const handleIsClickedTrue = () => {
    // setLeftToRightTab(false);
    setTimeout(() => {
      setIsClicked(true);
    }, 200);
  };
  const handleClose = async (e) => {
    setIsClicked(false);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (credentials.fullName === "") {
      setAlertInput(true);
    }
    if (credentials.email === "") {
      setAlertInput(true);
    }
    if (mobile === "") {
      setAlertInput(true);
    }
    if (pincode === "") {
      setAlertInput(true);
    } else {
      setAlertInput(false);
      // const formData = new FormData(e.target);
      axios
        .post(
          `https://www.99squarewall.com/consultation.php?fullName=${credentials.fName}&email=${credentials.email}&mobile=${mobile}&pincode=${pincode}&whatsappUpdate=${whatsappUpdate}&constructionUpdate=${constructionUpdate}`
        )
        // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
        // http://localhost/api/post-endpoint
        .then((response) => {
          setFormResponse(response.data.message);
          // setShowOTP(true);
          // toast('Thank For Consult with us our executive will contact you soon🙂');
          toast(
            "Thank For Consult with us our executive will contact you soon🙂",
            {
              // toast('🦄 Wow so easy!', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          // setIsClicked(true);
          const elements = document.getElementsByClassName("modal-backdrop");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.toggle("new-class");
            const divToRemove = elements[i];
            divToRemove.remove();
          }
          setTimeout(() => {
            setIsClicked(false);
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          setFormResponse("Error sending OTP");
        });
    }
  };

  const myStyle = {
    display: isClicked ? "none" : "",
    opacity: isClicked ? "0" : "",
  };

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff",
        text: "Schedule time with me",
        color: "#0069ff",
        textColor: "#ffffff",
        branding: false,
      });
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const items = [
    {
      quote:
        "I am truly amazed by the level of craftsmanship and attention to detail that 99SquareWall brought to my home. From the initial design discussions to the final touches, their team demonstrated professionalism and dedication. The result is a living space that not only reflects my style but also exceeds my expectations. I can't thank them enough for turning my house into a place I'm proud to call home.",
      name: "Pritesh Mahajan",
      place: "Kothrud, Pune",
    },
    {
      quote:
        "Working with 99SquareWall was a fantastic experience. Their expertise in commercial construction is evident in the stunning space they delivered for my business. The entire process was seamless, from the creative design phase to the flawless execution. I appreciate their commitment to quality and their ability to meet deadlines. I highly recommend 99SquareWall to anyone seeking top-notch construction services.",
      name: "Suresh Bhujbal",
      place: "Hinjewadi, Pune",
    },
    {
      quote:
        "I had the pleasure of collaborating with 99SquareWall on a residential project, and I'm thrilled with the results. Their team took the time to understand my vision and translated it into a design that perfectly captures my style. The attention to detail, quality of materials, and craftsmanship are remarkable. 99SquareWall transformed my space into a functional and beautiful home.",
      name: "Rohini Tyagi",
      place: "Koregaon Park, Pune",
    },
    {
      quote:
        "From the very beginning, 99SquareWall demonstrated a deep understanding of my aspirations for my new home. Their architects and builders worked in harmony, resulting in a seamless transformation. The dedication to achieving perfection and the precision in execution set them apart. The final result is a home that not only meets but surpasses my dreams.",
      name: "Rohit Mishra",
      place: "Punawale, Pune",
    },
    {
      quote:
        "I had the privilege of partnering with 99SquareWall on a commercial project, and their commitment to excellence is unparalleled. Their team combines aesthetics with functionality effortlessly. They transformed my vision into a space that captures the essence of my business. I am grateful for their professionalism, creativity, and dedication.",
      name: "Mahira Baig",
      place: "Koregaon Park, Pune",
    },
    {
      quote:
        "My experience with 99SquareWall was exceptional. Their architects and designers truly understand how to harmonize beauty and practicality. From the initial consultation to the final walk-through, their attention to detail and dedication to quality were evident. They turned my vision into reality while ensuring functionality and aesthetics were perfectly balanced.",
      name: "Suman Kothari",
      place: "Talegaon Dabhade, Pune",
    },
  ];

  const handleTransitionEnd = () => {
    const { current } = carouselRef;
    if (current) {
      const newIndex = current.getActiveIndex();
      setCurrentIndex(newIndex);

      // Manually reset the carousel to the beginning when it reaches the end
      if (newIndex === items.length - 1) {
        current.goTo(0);
      }
    }
  };

  const [stepActive, setStepActive] = useState("1");
  // alert('1');

  if (stepActive === "1") {
    setTimeout(() => {
      setStepActive("2");
    }, 4000);
  }
  if (stepActive === "2") {
    setTimeout(() => {
      setStepActive("3");
    }, 4000);
  }
  if (stepActive === "3") {
    setTimeout(() => {
      setStepActive("4");
    }, 4000);
  }
  if (stepActive === "4") {
    setTimeout(() => {
      setStepActive("5");
    }, 4000);
  }
  if (stepActive === "5") {
    setTimeout(() => {
      setStepActive("1");
    }, 4000);
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheck = () => {
    if (checkbox === false) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [borderPosition, setBorderPosition] = useState(0);

  const handleTab1 = () => {
    setTab1(true);
    setTab2(false);
    setTab3(false);
    setBorderPosition(0);
  };
  const handleTab2 = () => {
    setTab1(false);
    setTab2(true);
    setTab3(false);
    setBorderPosition(1);
  };
  const handleTab3 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(true);
    setBorderPosition(2);
  };

  const [faqs, setFaqs] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
    faq6: false,
    faq7: false,
    faq8: false,
  });
  const [showAll, setShowAll] = useState(false);

  const handleFAQ = (index) => {
    setFaqs((prevFaqs) => ({
      ...prevFaqs,
      [index]: !prevFaqs[index],
    }));
  };

  const truncateAnswer = (answer) => {
    const maxLength = 100; // Adjust this value according to your preference
    if (answer.length > maxLength) {
      return `${answer.slice(0, maxLength)}...`;
    }
    return answer;
  };

  const questions = [
    { title: "Question 1", answer: "Answer to question 1..." },
    { title: "Question 2", answer: "Answer to question 2..." },
    { title: "Question 3", answer: "Answer to question 3..." },
    { title: "Question 4", answer: "Answer to question 4..." },
    { title: "Question 5", answer: "Answer to question 5..." },
    { title: "Question 6", answer: "Answer to question 6..." },
    { title: "Question 7", answer: "Answer to question 7..." },
    { title: "Question 8", answer: "Answer to question 8..." },
  ];

  const renderQuestions = () => {
    const endIndex = showAll ? questions.length : 6;
    return questions.slice(0, endIndex).map((question, index) => (
      <div
        key={index}
        className={
          faqs[`faq${index + 1}`] ? "faq-box faq-box-active" : "faq-box"
        }
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <button
          className="top"
          onClick={() => handleFAQ(`faq${index + 1}`)}
          type="button"
        >
          <h1>
            {index + 1}. {question.title}
          </h1>
          {faqs[`faq${index + 1}`] ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
              </svg>
            </>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
              </svg>
            </>
          )}
        </button>
        <div className="faq-desc">
          <p>
            {faqs[`faq${index + 1}`]
              ? question.answer
              : truncateAnswer(question.answer)}
          </p>
        </div>
      </div>
    ));
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <Helmet>
        <title>99SquareWall - Pune's No.01 Lock and key construction company</title>
        <meta name="description" content="Welcome to 99SquareWall, your premier construction company specializing in turning dreams into reality. Explore our exceptional residential and commercial projects. Schedule a consultation today to begin your journey to extraordinary spaces." />
        <meta name="keywords" content="construction, residential projects, commercial projects, 99SquareWall, home construction, building services" />
      </Helmet>
      <Header />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* <!-- Modal --> */}
      {isClicked === true && (
        <>
          <div className={isClicked === true ? "modal-bg" : "modal-bg none"}>
            <div className="modal-form">
              <div className="modal-container">
                {/* <div className="modal-top">
                  <div className="free-consultation">
                    <label className="free-consultation--wraper">
                      Call us for Free Consultation
                      <span className="free-consultation--title--icon">
                        <a href="tel:7499295173">
                          <img
                            className="call-img"
                            loading="lazy"
                            src="/img/24-hours-support.gif"
                            width="38"
                            height="38"
                            alt="To paint your home, please give us a call at"
                            title="phone-call-desktop"
                          />
                          <span className="mob-num">+91 7499295173</span>
                        </a>
                      </span>
                    </label>
                  </div>
                </div> */}
                <div className="modal-middle">
                  <p className="heading">Let our experts help you!</p>
                  <button onClick={handleClose}>
                    <img src="/img/letter-x.gif" />
                  </button>
                </div>
                <div className="modal-bottom">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">
                        Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Your Full Name"
                        value={credentials.fullName}
                        onChange={onChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Eg: myname@gmail.com"
                        value={credentials.email}
                        onChange={onChange}
                      />
                    </div>
                    <div className="row align-items-center mb-3">
                      <div className="col-auto mobile">
                        <label htmlFor="mobile" className="form-label">
                          Mobile Number*
                        </label>
                        <div className="mobile-container">
                          <img src="/img/india.png" />
                          <p>+91</p>
                        </div>
                        <input
                          type="number"
                          className="form-control2"
                          id="mobile"
                          name="mobile"
                          placeholder="10 digits"
                          value={mobile}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          required
                        />
                      </div>
                      <div className="col-auto">
                        <label htmlFor="pincode" className="form-label">
                          Pin Code*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="6 digit pin code"
                          value={pincode}
                          onChange={handlePincode}
                        />
                      </div>
                    </div>
                    {/* {location.pathname === '/' &&(
                                                            <>
                                                                Home
                                                            </>
                                                        )}
                                                        {location.pathname === '/homeConstruction' &&(
                                                            <>
                                                                homeConstruction
                                                            </>
                                                        )}
                                                        {location.pathname === '/commercialBuilding' &&(
                                                            <>
                                                                commercialBuilding
                                                            </>
                                                        )}
                                                        {location.pathname === '/interior' &&(
                                                            <>
                                                                interior
                                                            </>
                                                        )}
                                                        {location.pathname === '/renovation' &&(
                                                            <>
                                                                renovation
                                                            </>
                                                        )} */}
                    <div className="whatsapp-update mb-3">
                      <label htmlFor="fullName" className="form-label mb-3">
                        Update me on WhatsApp
                      </label>
                      <button type="button" onClick={handleWhatsappUpdate}>
                        <img src="/img/whatsapp1.png" alt="" />
                        <p className={whatsappUpdate === "off" ? "" : "on"}>
                          {whatsappUpdate === "off" ? "off" : "on"}
                        </p>
                      </button>
                    </div>
                    <div className="any-construction">
                      <p>Do you own a plot ?</p>
                      <div className="yes-no-button">
                        <button
                          type="button"
                          className={
                            constructionUpdate === "yes"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateYes}
                        >
                          yes
                        </button>
                        <button
                          type="button"
                          className={
                            constructionUpdate === "no"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateNo}
                        >
                          no
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "11px",
                        marginBottom: "-7px",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="termCondition"
                        id="termCondition"
                        className="myinput"
                        onClick={handleCheck}
                      />
                      <label
                        htmlFor="termCondition"
                        className="termConditionLabel"
                        onClick={handleCheck}
                      >
                        *By submitting this form, I confirm that I have read and
                        agreed to accept 99squarewall{" "}
                        <a href="">privacy policy</a>
                      </label>
                    </div>
                    <button
                      type="button"
                      className="final-button"
                      onClick={handleClick}
                    >
                      Start Building
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* --========== Hero Section Start ==========-- */}
      <div>
        <img src="/img/hero-img.png" alt="" className="desktop" />
        <img src="/img/hero-mob.png" alt="" className="mobile2" />
      </div>
      {/* --========== Hero Section Start ==========-- */}

      <form className="home-form">
        <p className="heading">Let our experts help you!</p>
        <div className="mb-3">
          <label htmlFor="fullName" className="form-label">
            Name*
          </label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            name="fName"
            placeholder="Your Full Name"
            value={credentials.fName}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email*
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Eg: myname@gmail.com"
            value={credentials.email}
            onChange={onChange}
          />
        </div>
        <div className="row mb-3">
          <div className="col-auto mobile">
            <label htmlFor="mobile" className="form-label">
              Mobile Number*
            </label>
            <div className="mobile-container">
              <img src="/img/india.png" />
              <p>+91</p>
            </div>
            <input
              type="number"
              className="form-control2"
              id="mobile"
              name="mobile"
              placeholder="10 digits"
              value={mobile}
              onChange={handleMobile}
              required
            />
          </div>
          <div className="col-auto">
            <label htmlFor="pincode" className="form-label">
              Pin Code*
            </label>
            <input
              type="number"
              className="form-control"
              id="pincode"
              name="pincode"
              placeholder="Pin Code"
              value={pincode}
              onChange={handlePincode}
            />
          </div>
        </div>
        <div className="whatsapp-update whatsapp-update2 mb-3">
          <label htmlFor="fullName" className="form-label mb-3">
            Update me on WhatsApp
          </label>
          <button type="button" onClick={handleWhatsappUpdate}>
            <img src="/img/whatsapp1.png" alt="" />
            <p className={whatsappUpdate === "off" ? "" : "on"}>
              {whatsappUpdate === "off" ? "off" : "on"}
            </p>
          </button>
        </div>
        <div className="any-construction any-construction2">
          <p>Do you own a plot ?</p>
          <div className="yes-no-button yes-no-button2">
            <button
              type="button"
              className={
                constructionUpdate === "yes" ? "button active" : "button"
              }
              onClick={handleConstructionUpdateYes}
            >
              yes
            </button>
            <button
              type="button"
              className={
                constructionUpdate === "no" ? "button active" : "button"
              }
              onClick={handleConstructionUpdateNo}
            >
              no
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            marginTop: "11px",
            marginBottom: "-7px",
          }}
        >
          <input
            type="checkbox"
            name="termCondition"
            id="termCondition"
            className="myinput"
            onClick={handleCheck}
          />
          <label
            htmlFor="termCondition"
            className="termConditionLabel termConditionLabel2"
            onClick={handleCheck}
          >
            *By submitting this form, I confirm that I have read and agreed to
            accept 99squarewall <a href="">privacy policy</a>
          </label>
        </div>
        <button type="button" className="final-button" onClick={handleClick}>
          Start Building
        </button>
      </form>

      {/* --========== Turn key Services Start ==========-- */}
      <section
        id="pricing"
        className="pricing padding-top2"
        style={{ background: "#fff", padding: "80px 0" }}
      >
        <div className="container howitwork-container">
          <div className="section-title">
            <h1>
              Why choose <font color="#002dfb">99SquareWall</font>
            </h1>
            <p>Seamless excellence from concept to creation</p>
          </div>
          <div className="whyChooseus_whyInteriorBox__4NFp7 displayGrid grid5">
            <div style={{ display: "flex", paddingTop: "50px" }}>
              <div className="whyChooseus_whyItem__oCHhb2 plr whyChooseus_whyItem__oCHhb plr">
                <figure>
                  <img
                    alt="made order"
                    src="/whychooseus/1.builder.png"
                    width="54"
                    height="54"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6 className="f500 font16 text000 mb12 mt16 textCenter lineheight20">
                  No Third-Party Contractors
                </h6>
                <p className="textCenter">
                  {" "}
                  We do not Handover any project to third-party contractors to ensure quality & trust.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb2 plr whyChooseus_whyItem__oCHhb plr">
                <figure>
                  <img
                    alt="made order"
                    src="/whychooseus/2.transparency.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6 className="f500 font16 text000 mb12 mt16 textCenter lineheight20">
                  Transparency
                </h6>
                <p className="textCenter">
                  {" "}
                  In detail specification and quotations before starting your home construction journey.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb2 plr whyChooseus_whyItem__oCHhb plr">
                <figure>
                  <img
                    alt="made order"
                    src="/whychooseus/3.quality.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6 className="f500 font16 text000 mb12 mt16 textCenter lineheight20">
                  Quality Checks
                </h6>
                <p className="textCenter">
                  Every step involves checks and updates by our experts.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb2 plr whyChooseus_whyItem__oCHhb plr">
                <figure>
                  <img
                    alt="made order"
                    src="/whychooseus/4.on-time.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6 className="f500 font16 text000 mb12 mt16 textCenter lineheight20">
                  On-Time Delivery
                </h6>
                <p className="textCenter">
                  {" "}
                  We have a full control over workmanship and material supply this will lead to on-time work done.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --========== Turn key Services End ==========-- */}

      {/* --========== Turn key Services Start ==========-- */}
      {/* <section
        id="pricing"
        className="pricing padding-top2"
        style={{ background: "rgb(228 236 249)", padding: "80px 0" }}
      >
        <div className="container howitwork-container">
          <div className="section-title">
            <h1>
              Construction <font color="#002dfb">Packages</font>
            </h1>
            <p>Choose the package that suits you.</p>
          </div>
          <div className="tab-packages">
            <ul className="tab-button-group">
              {tab1 === true ? (
                <>
                  <li className="tab1 active" onClick={handleTab1}>
                    Tab 1
                  </li>
                </>
              ) : (
                <>
                  <li className="tab1" onClick={handleTab1}>
                    Tab 1
                  </li>
                </>
              )}
              {tab2 === true ? (
                <>
                  <li className="tab2 active" onClick={handleTab2}>
                    Tab 2
                  </li>
                </>
              ) : (
                <>
                  <li className="tab2" onClick={handleTab2}>
                    Tab 2
                  </li>
                </>
              )}
              {tab3 === true ? (
                <>
                  <li className="tab3 active" onClick={handleTab3}>
                    Tab 3
                  </li>
                </>
              ) : (
                <>
                  <li className="tab3" onClick={handleTab3}>
                    Tab 3
                  </li>
                </>
              )}
              <li
                className="border-bottom"
                style={{
                  width: `${100 / 13.7}%`,
                  transform: `translateX(${borderPosition * 100}%)`,
                }}
              />
            </ul>
          </div>

          {tab1 === true && (
            <>
              <Carousel1
                ref={carouselRef}
                onTransitionEnd={handleTransitionEnd}
                breakPoints={breakPoints2}
                pagination={false}
                showArrows={true}
                enableSwipe={true}
                enableAutoPlay={false}
                autoPlaySpeed={3000}
              >
                <Item3>
                  <section className="pricing-section">
                    <div className="pricing-1">
                      <div className="pricing-1-div">
                        <h3 className="heading">
                          Elegant Essence Package
                          <h3>₹1800/sq.ft(Incl GST)</h3>
                        </h3>
                      </div>
                      <div className="line" />
                      <div className="container frequently-asked-question">
                        <div className="faq-list">
                          {renderQuestions()}
                          {!showAll && (
                            <button
                              className="show-more-btn"
                              onClick={toggleShowAll}
                            >
                              Explore more
                            </button>
                          )}
                          {showAll && (
                            <button
                              className="show-less-btn"
                              onClick={toggleShowAll}
                            >
                              Explore less
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </Item3>
                <Item3>
                  <section className="pricing-section">
                    <div className="pricing-1">
                      <div className="pricing-1-div">
                        <h3 className="heading">
                          Elegant Essence Package
                          <h3>₹1800/sq.ft(Incl GST)</h3>
                        </h3>
                      </div>
                      <div className="line" />
                      <div className="container frequently-asked-question">
                        <div className="faq-list">
                          {renderQuestions()}
                          {!showAll && (
                            <button
                              className="show-more-btn"
                              onClick={toggleShowAll}
                            >
                              Explore more
                            </button>
                          )}
                          {showAll && (
                            <button
                              className="show-less-btn"
                              onClick={toggleShowAll}
                            >
                              Explore less
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </Item3>
                <Item3>
                  <section className="pricing-section">
                    <div className="pricing-1">
                      <div className="pricing-1-div">
                        <h3 className="heading">
                          Elegant Essence Package
                          <h3>₹1800/sq.ft(Incl GST)</h3>
                        </h3>
                      </div>
                      <div className="line" />
                      <div className="container frequently-asked-question">
                        <div className="faq-list">
                          {renderQuestions()}
                          {!showAll && (
                            <button
                              className="show-more-btn"
                              onClick={toggleShowAll}
                            >
                              Explore more
                            </button>
                          )}
                          {showAll && (
                            <button
                              className="show-less-btn"
                              onClick={toggleShowAll}
                            >
                              Explore less
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </Item3>
                <Item3>
                  <section className="pricing-section">
                    <div className="pricing-1">
                      <div className="pricing-1-div">
                        <h3 className="heading">
                          Elegant Essence Package
                          <h3>₹1800/sq.ft(Incl GST)</h3>
                        </h3>
                      </div>
                      <div className="line" />
                      <div className="container frequently-asked-question">
                        <div className="faq-list">
                          {renderQuestions()}
                          {!showAll && (
                            <button
                              className="show-more-btn"
                              onClick={toggleShowAll}
                            >
                              Explore more
                            </button>
                          )}
                          {showAll && (
                            <button
                              className="show-less-btn"
                              onClick={toggleShowAll}
                            >
                              Explore less
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </Item3>
              </Carousel1>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: "176px",
                  marginTop: "-13px",
                }}
                className="TC"
              >
                *<p
                  style={{
                    fontSize: "12px",
                    color: "#002dfb",
                    marginRight: "15px",
                  }}
                  className="p1"
                >The prices are exclusive of Plinth and Terrace charges</p>
                *<p
                  style={{
                    fontSize: "12px",
                    color: "#002dfb",
                  }}
                >T&C Apply</p>
              </div>
            </>
          )}
        </div>
      </section> */}
      {/* --========== Turn key Services End ==========-- */}

      {/* --========== How It Work Start ==========-- */}
      <section
        id="pricing"
        className="pricing howItWork padding-top"
        style={{ padding: "80px 0" }}
      >
        <div className="container howitwork-container">
          <div className="section-title">
            <h1 style={{ color: "white" }}>
              How it <font color="#fff">works</font>
            </h1>
            <p style={{ color: "white" }}>
              Seamless excellence from concept to creation
            </p>
          </div>
          <div className="step-by-step-how-it-work">
            {/* <div className="img">
              {stepActive === "1" ? (
                <>
                  <img src="/img/howItWork/1consultant.png" alt="" />
                </>
              ) : (
                <></>
              )}
              {stepActive === "2" ? (
                <>
                  <img src="/img/howItWork/2designAndPlanning.png" alt="" />
                </>
              ) : (
                <></>
              )}
              {stepActive === "3" ? (
                <>
                  <img src="/img/howItWork/3permitsAndApproval.png" alt="" />
                </>
              ) : (
                <></>
              )}
              {stepActive === "4" ? (
                <>
                  <img src="/img/howItWork/4construction.png" alt="" />
                </>
              ) : (
                <></>
              )}
              {stepActive === "5" ? (
                <>
                  <img
                    src="/img/howItWork/5completionAndHandover-removebg-preview.png"
                    alt=""
                  />
                </>
              ) : (
                <></>
              )}
            </div> */}
            <div className="">
              <ul className="HowItWorks_stepsBx__HY4oU">
                {stepActive === "1" ? (
                  <>
                    <li className="HowItWorks_active__4JMUw">
                      <span>1</span>
                      <label>Consultation</label>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="">
                      <span>1</span>
                      <label>Consultation</label>
                    </li>
                  </>
                )}
                {stepActive === "2" ? (
                  <>
                    <li className="HowItWorks_active__4JMUw">
                      <span>2</span>
                      <label>Desgin & Plan</label>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="">
                      <span>2</span>
                      <label>Desgin & Plan</label>
                    </li>
                  </>
                )}
                {stepActive === "3" ? (
                  <>
                    <li className="HowItWorks_active__4JMUw">
                      <span>3</span>
                      <label>Permit & Approvals</label>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="">
                      <span>3</span>
                      <label>Permit & Approvals</label>
                    </li>
                  </>
                )}
                {stepActive === "4" ? (
                  <>
                    <li className="HowItWorks_active__4JMUw">
                      <span>4</span>
                      <label>Construction</label>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="">
                      <span>4</span>
                      <label>Construction</label>
                    </li>
                  </>
                )}
                {stepActive === "5" ? (
                  <>
                    <li className="HowItWorks_active__4JMUw">
                      <span>5</span>
                      <label>Completion & Handover</label>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="">
                      <span>5</span>
                      <label>Completion & Handover</label>
                    </li>
                  </>
                )}
              </ul>
              <ul className="HowItWorks_context__T0cn9" style={{ marginTop: "40px", }}>
                {stepActive === "1" ? (
                  <>
                    <li className="">
                      <h6>Start Building A Consultation</h6>
                      <p>
                        Begin your journey by sharing your vision with us. Our
                        experts are ready to listen, understand, and provide
                        insights tailored to your needs. We'll explore the
                        possibilities together, ensuring a strong foundation for
                        your project.
                      </p>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {stepActive === "2" ? (
                  <>
                    <li className="">
                      <h6>Design and Planning</h6>
                      <p>
                        Watch your ideas transform into tangible plans. Our
                        seasoned architects and designers will craft detailed
                        blueprints, aligning creativity with practicality. The
                        result? A roadmap that brings your vision closer to
                        reality.
                      </p>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {stepActive === "3" ? (
                  <>
                    <li className="">
                      <h6>Permits and Approvals</h6>
                      <p>
                        Navigating the complex landscape of permits and
                        approvals is our expertise. We handle the paperwork and
                        liaise with authorities, ensuring your project complies
                        with regulations. Your peace of mind is our priority.
                      </p>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {stepActive === "4" ? (
                  <>
                    <li className="">
                      <h6>Construction</h6>
                      <p>
                        From groundbreaking to finishing touches, witness your
                        dreams taking shape. Our skilled craftsmen, guided by a
                        commitment to excellence, turn designs into structures.
                        Experience each phase with confidence.
                      </p>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {stepActive === "5" ? (
                  <>
                    <li className="">
                      <h6>Completion and Handover</h6>
                      <p>
                        The moment of fulfillment arrives as we hand over your
                        completed project. Our attention to detail and
                        dedication to quality ensure that your vision is not
                        only met but exceeded. Welcome to the realization of
                        your dreams.
                      </p>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* --========== How It Work End ==========-- */}

      {/* --========== Why Choose Us Start ==========-- */}
      <section
        id="pricing"
        className="pricing padding-top2"
        style={{ background: "#e4ecf9", padding: "80px 0" }}
      >
        <div className="container howitwork-container">
          <div className="section-title">
            <h1>
              Our <font color="#002dfb">Advantages</font>
            </h1>
            <p>Seamless excellence from concept to creation</p>
          </div>
          <div className="whyChooseus_whyInteriorBox__4NFp7 displayGrid grid5">
            <div style={{ display: "flex", paddingTop: "50px" }}>
              <div className="whyChooseus_whyItem__oCHhb plr hover">
                <figure
                  style={{
                    margin: "0 0 1rem",
                    background: "#4162ff",
                    borderRadius: "5px",
                    width: "80px",
                    height: "80px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="made order"
                    src="/whychooseus1/sketch.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6
                  className="f500 font16 text000 mb12 mt16 textCenter lineheight20"
                  style={{ color: "black" }}
                >
                  Customized Design Solutions
                </h6>
                <p className="textCenter" style={{ color: "black" }}>
                  {" "}
                  Your dream, our creation. Our team of design experts
                  translates your vision into customized design solutions that
                  resonate with your unique needs and aspirations. Innovation
                  meets functionality as we craft spaces that tell your story.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb plr hover">
                <figure
                  style={{
                    margin: "0 0 1rem",
                    background: "#4162ff",
                    borderRadius: "5px",
                    width: "80px",
                    height: "80px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="made order"
                    src="/whychooseus1/idea.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                  />
                </figure>
                <h6
                  className="f500 font16 text000 mb12 mt16 textCenter lineheight20"
                  style={{ color: "black" }}
                >
                  Comprehensive Project Management
                </h6>
                <p className="textCenter" style={{ color: "black" }}>
                  {" "}
                  From inception to the final touch, we handle every detail.
                  Leave the complexities to us as we orchestrate seamless
                  coordination among various aspects of your project. With
                  99SQUAREWALL, you're free to focus on your vision while we
                  manage the rest.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb plr hover">
                <figure
                  style={{
                    margin: "0 0 1rem",
                    background: "#4162ff",
                    borderRadius: "5px",
                    width: "80px",
                    height: "80px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="made order"
                    src="/whychooseus1/quality.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6
                  className="f500 font16 text000 mb12 mt16 textCenter lineheight20"
                  style={{ color: "black" }}
                >
                  High-Quality Materials and Workmanship
                </h6>
                <p className="textCenter" style={{ color: "black" }}>
                  Craftsmanship that stands the test of time. We source only the
                  finest materials and deploy skilled artisans who take pride in
                  their work. The result? A masterpiece that reflects our
                  commitment to quality and attention to detail.
                </p>
              </div>
              <div className="whyChooseus_whyItem__oCHhb plr hover">
                <figure
                  style={{
                    margin: "0 0 1rem",
                    background: "#4162ff",
                    borderRadius: "5px",
                    width: "80px",
                    height: "80px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="made order"
                    src="/whychooseus1/express-delivery.png"
                    width="47"
                    height="50"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </figure>
                <h6
                  className="f500 font16 text000 mb12 mt16 textCenter lineheight20"
                  style={{ color: "black" }}
                >
                  Competitive Pricing
                </h6>
                <p className="textCenter" style={{ color: "black" }}>
                  {" "}
                  We understand the importance of delivering on time and within
                  budget. With 99SQUAREWALL, punctuality is a promise. Our
                  streamlined processes ensure efficient execution, while our
                  competitive pricing ensures your investment is both sensible
                  and valuable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --========== Why Choose Us End ==========-- */}

      {/* --========== Start Building A Free Consultation Start ==========-- */}
      <section id="cta" className="cta">
        <div className="container ctacontainer">
          <div className="row">
            <div className="col-lg-9 text-center text-lg-start">
              <h3>Ready to Transform?</h3>
              <p>Seamless excellence from concept to creation</p>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              {/* <a href="#"> */}
              <button
                type="button"
                className="cta-btn align-middle"
                onClick={handleIsClickedTrue}
              >
                Start Building
                <span><i class="fa-solid fa-arrow-right"></i></span>
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </section>
      {/* --========== Start Building A Free Consultation End ==========-- */}

      {/* --========== Brand For You Start ==========-- */}
      <section
        id="clients"
        className="clients"
        style={{ background: "#e4ecf9", padding: "80px 0" }}
      >
        <div className="container clientcontainer">
          <div className="section-title">
            <h1>
              THE<font color="#002dfb"> BEST BRANDS</font> FOR YOU
            </h1>
            <p>Seamless excellence from concept to creation</p>
          </div>
          <div style={{ marginTop: "-5rem" }}>
            <Carousel
              breakPoints={breakPoints}
              pagination={false}
              showArrows={false}
              enableSwipe={true}
              enableAutoPlay={true}
              autoPlaySpeed={3000}
            >
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand1.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand2.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand3.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand4.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand5.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand6.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                    naptha_cursor="text"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand7.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="0" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand8.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand9.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand10.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand11.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand12.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand13.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand14.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand15.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand16.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand17.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand18.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
              <Item>
                <div tabIndex="-1" className="sc-fsQiph XxSAY">
                  <img
                    src="/img/clients/brand19.png"
                    alt=""
                    width="200rem"
                    className="img-fluid"
                  />
                </div>
              </Item>
            </Carousel>
          </div>
        </div>
      </section>
      {/* --========== Brand For You End ==========-- */}

      {/* --========== Testimonials Start ==========-- */}
      <section id="testimonials" className="testimonials testimonials-bg">
        <div className="container testimonialcontainer">
          <div className="section-title">
            <h1 style={{ color: "#fff" }}>
              What they are<font color="#fff"> saying about</font> us
            </h1>
          </div>
          <div className="mb">
            <Carousel1
              ref={carouselRef}
              onTransitionEnd={handleTransitionEnd}
              breakPoints={breakPoints1}
              pagination={false}
              showArrows={false}
              enableSwipe={true}
              enableAutoPlay={false}
              autoPlaySpeed={3000}
              onNextStart={true}
            >
              {items.map((item, index) => (
                <Item1>
                  <div tabIndex="-1" className="sc-qRumB hPSzWg">
                    <div className="testimonial-item">
                      <p>
                        <span>"</span>
                        {item.quote}
                        <span>"</span>
                      </p>
                      <h3>{item.name}</h3>
                      <h4>{item.place}</h4>
                    </div>
                  </div>
                </Item1>
              ))}
            </Carousel1>
          </div>
        </div>
      </section>
      {/* --========== Testimonials End ==========-- */}

      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function Blog({ category }) {
    const [fix, setFix] = useState(false);
    useEffect(() => {
        document.title = `${category} - 99SquareWall - Crafting Dreams into Structures`; // Replace 'My App' with your desired initial title
    }, []);
    
    function setFixed() {
        if (window.scrollY >= 610) {
            setFix(true);
        } else {
            setFix(false);
        }
    }
    window.addEventListener("scroll", setFixed);

    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({ url: 'https://calendly.com/shouryanarwade37/shedule-event?background_color=000000&text_color=ffffff&primary_color=ffffff', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: false });
        }
    };

    const [selects, setSelects] = useState("");
    const [credentials, setCredentials] = useState(
        {
            fName: "",
            lName: "",
            mobile: "",
            email: "",
            city: "",
            lookingFor: "",
        }
    );
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }
    const xyz = () => {
        setCredentials(
            {
                fName: credentials.fName,
                lName: credentials.lName,
                mobile: credentials.mobile,
                email: credentials.email,
                city: selects,
                lookingFor: credentials.lookingFor,
            }
        )
    }
    const [alertInput, setAlertInput] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = async (e) => {
        e.preventDefault();
        if (credentials.fName === '') {
            setAlertInput(true)
        }if (credentials.lName === '') {
            setAlertInput(true)
        }if (credentials.mobile === '') {
            setAlertInput(true)
        }if (credentials.email === '') {
            setAlertInput(true)
        }if (credentials.city === '') {
            setAlertInput(true)
        }if (credentials.lookingFor === '') {
            setAlertInput(true)
        } else {
            setAlertInput(false);
            // const formData = new FormData(e.target);
            axios.post(`https://www.99squarewall.com/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
            // http://localhost/api/post-endpoint
            .then(response => {
                setFormResponse(response.data.message);
                toast('Thank For Consult with us our executive will contact you soon🙂', {
                    // toast('🦄 Wow so easy!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                hideModal();
                setIsClicked(true);
                const elements = document.getElementsByClassName('modal-backdrop');    
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.toggle('new-class');
                    const divToRemove = elements[i];
                    divToRemove.remove();
                }
                setTimeout(() => {
                    setIsClicked(false);
                }, 1000);
                setCredentials(
                    {
                        fName: "",
                        lName: "",
                        mobile: "",
                        email: "",
                        city: "",
                        lookingFor: "",
                    }
                )
            })
            .catch(error => {
                console.error(error);
                setFormResponse('Error sending OTP');
            });
        }
    }

    const hideModal = () => {
        // setTimeout(() => {
        //     window.location.href('/');
        // }, 1000);
    }

    return (
        <>
            <Header />

            {/* Main Section Start */}
                <div className="main">
                    <div className="container">
                        <div className="row">
                            <div className="blogSection">

                                <div className="blogFlex">
                                    <Link to="/blog-content" className="blogContent">
                                        <div className="blogImg">
                                            <img src="/img/blog1.png" alt="blog" />
                                        </div>
                                        <div className="blogContentText">
                                            <div>
                                                <label>The journey of thousand miles begins with single steps.</label>
                                                <p className="font14 text666">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo recusandae, nihil sapiente non doloremque iusto natus voluptatem hic architecto iure.</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                                    <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
                                                </svg>
                                                <span>Jun 17 2023</span>
                                            </div>
                                            <div className="blogBtn">
                                                <button>Read More</button>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/blog-content" className="blogContent">
                                        <div className="blogImg">
                                            <img src="/img/blog2.png" alt="blog" />
                                        </div>
                                        <div className="blogContentText">
                                            <div>
                                                <label>The journey of thousand miles begins with single steps.</label>
                                                <p className="font14 text666">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo recusandae, nihil sapiente non doloremque iusto natus voluptatem hic architecto iure.</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                                    <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
                                                </svg>
                                                <span>Jun 17 2023</span>
                                            </div>
                                            <div className="blogBtn">
                                                <button>Read More</button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            {/* Main Section End */}
        
            <Footer/>
        </>
    )
}

import React, { useState, useEffect } from "react";
import Home from "./component/Home";
import Schedule from "./component/Schedule";
import HomeConstruction from "./component/HomeConstruction";
import CommercialBuilding from "./component/CommercialBuilding";
import Renovation from "./component/Renovation";
import Interior from "./component/Interior";
import Blog from "./component/Blog";
import BlogContent from "./component/BlogContent";
import AboutUs from "./component/AboutUs";
import TAndCs from "./component/TAndCs";
import PrivacyPolicy from "./component/PrivacyPolicy";
import Projects from "./component/Projects";
import ProjectsDetails from "./component/ProjectsDetails";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

export default function App() {
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    // Set the loading bar to 100% to hide it when the component mounts
    setLoading(100);
  }, []);

  const handleLoading = (progress) => {
    setLoading(progress);
  };

  return (
    <>
      <Router>
        <LoadingBar color="#0f4a8a" height={4} progress={loading} />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                handleLoading={handleLoading}
                category="99SquareWall - Pune's No.01 Lock and key construction company"
              />
            }
          />
          <Route
            path="/homeConstruction"
            element={
              <HomeConstruction
                handleLoading={handleLoading}
                category="Home Construction"
              />
            }
          />
          <Route
            path="/commercialBuilding"
            element={
              <CommercialBuilding
                handleLoading={handleLoading}
                category="Commercial Construction"
              />
            }
          />
          <Route
            path="/renovation"
            element={
              <Renovation handleLoading={handleLoading} category="Renovation" />
            }
          />
          <Route
            path="/interior"
            element={
              <Interior handleLoading={handleLoading} category="Interior" />
            }
          />
          <Route
            path="/blog"
            element={
              <Blog handleLoading={handleLoading} category="Our Blogs" />
            }
          />
          <Route
            path="/blog-content"
            element={
              <BlogContent handleLoading={handleLoading} category="Blog View" />
            }
          />
          <Route
            path="/company/about-us"
            element={
              <AboutUs handleLoading={handleLoading} category="About Company" />
            }
          />
          <Route
            path="/company/terms-and-condition"
            element={
              <TAndCs
                handleLoading={handleLoading}
                category="Terms And Condition | 99SquareWall"
              />
            }
          />
          <Route
            path="/company/privacy-policy"
            element={
              <PrivacyPolicy
                handleLoading={handleLoading}
                category="Privacy And Policy | 99SquareWall"
              />
            }
          />
          <Route
            path="/our-projects"
            element={
              <Projects handleLoading={handleLoading} category="Our Projects" />
            }
          />
          <Route
            path="/projects/projects-details/:id"
            element={
              <ProjectsDetails
                handleLoading={handleLoading}
                category="Project View"
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}
// CommercialBuilding
// HomeConstruction

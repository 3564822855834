import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import Item from "./Item";

export default function Footer() {
  let navigate = useNavigate();
  let location = useLocation();

  const [fix1, setFix1] = useState(false);

  function setFixed1() {
    if (window.scrollY >= 200) {
      setFix1(true);
    } else {
      setFix1(false);
    }
  }
  window.addEventListener("scroll", setFixed1);

  const [hideBox, setHideBox] = useState(true);
  const hideBoxfunc = () => {
    setHideBox(false);
  };

  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);

  const handleFAQ1 = () => {
    if (faq1 === false) {
      setFaq1(true);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
    } else {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
    }
  };
  const handleFAQ2 = () => {
    if (faq2 === false) {
      setFaq1(false);
      setFaq2(true);
      setFaq3(false);
      setFaq4(false);
    } else {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
    }
  };
  const handleFAQ3 = () => {
    if (faq3 === false) {
      setFaq1(false);
      setFaq2(false);
      setFaq3(true);
      setFaq4(false);
    } else {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
    }
  };
  const handleFAQ4 = () => {
    if (faq4 === false) {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(true);
    } else {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
    }
  };
  const handleFAQ5 = () => {
    if (faq5 === false) {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
      setFaq5(true);
    } else {
      setFaq1(false);
      setFaq2(false);
      setFaq3(false);
      setFaq4(false);
      setFaq5(false);
    }
  };

  const [credentials, setCredentials] = useState({
    fullName: "",
    email: "",
  });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [whatsappUpdate, setWhatsappUpdate] = useState("on");
  const [constructionUpdate, setConstructionUpdate] = useState("yes");

  const handleWhatsappUpdate = () => {
    if (whatsappUpdate === "off") {
      setWhatsappUpdate("on");
    } else {
      setWhatsappUpdate("off");
    }
  };
  const handleConstructionUpdateYes = () => {
    setConstructionUpdate("yes");
  };
  const handleConstructionUpdateNo = () => {
    setConstructionUpdate("no");
  };

  const handleMobile = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 10) {
      setMobile(inputValue);
    }
  };
  const handlePincode = (event) => {
    // Get the input value and ensure it doesn't exceed 5 characters
    let inputValue = event.target.value;
    if (inputValue.length <= 6) {
      setPincode(inputValue);
    }
  };
  const [alertInput, setAlertInput] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const handleIsClickedTrue = () => {
    setIsClicked(true);
  };
  const handleClose = async (e) => {
    setIsClicked(false);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (credentials.fullName === "") {
      setAlertInput(true);
    }
    if (credentials.email === "") {
      setAlertInput(true);
    }
    if (mobile === "") {
      setAlertInput(true);
    }
    if (pincode === "") {
      setAlertInput(true);
    } else {
      setAlertInput(false);
      // const formData = new FormData(e.target);
      axios
        .post(
          `https://www.99squarewall.com/consultation.php?fullName=${credentials.fullName}&email=${credentials.email}&mobile=${mobile}&pincode=${pincode}&whatsappUpdate=${whatsappUpdate}&constructionUpdate=${constructionUpdate}`
        )
        // axios.post(`http://localhost/homeConstruction-db/consultation.php?fName=${credentials.fName}&lName=${credentials.lName}&mobile=${credentials.mobile}&email=${credentials.email}&city=${credentials.city}&lookingFor=${credentials.lookingFor}`)
        // http://localhost/api/post-endpoint
        .then((response) => {
          setFormResponse(response.data.message);
          // setShowOTP(true);
          // toast('Thank For Consult with us our executive will contact you soon🙂');
          toast(
            "Thank For Consult with us our executive will contact you soon🙂",
            {
              // toast('🦄 Wow so easy!', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setIsClicked(true);
          const elements = document.getElementsByClassName("modal-backdrop");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.toggle("new-class");
            const divToRemove = elements[i];
            divToRemove.remove();
          }
          setTimeout(() => {
            setIsClicked(false);
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          setFormResponse("Error sending OTP");
        });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Save the state to local storage when the component unmounts
    localStorage.setItem("navbarIsOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  useEffect(() => {
    // Retrieve the state from local storage when the component mounts
    const savedNavbarState = localStorage.getItem("navbarIsOpen");
    if (savedNavbarState !== null) {
      setIsOpen(JSON.parse(savedNavbarState));
    }
  }, []);

  const [checkbox, setCheckbox] = useState(false);
  const handleCheck = () => {
    if (checkbox === false) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };

  function setFixed1() {
    if (window.scrollY >= 900) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }
  window.addEventListener("scroll", setIsOpen);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []); // Run only once on component mount

  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 6 },
    { width: 768, itemsToShow: 6 },
    { width: 1200, itemsToShow: 6 },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* <!-- Modal --> */}
      {isClicked === true && (
        <>
          <div className={isClicked === true ? "modal-bg" : "modal-bg none"}>
            <div className="modal-form">
              <div className="modal-container">
                {/* <div className="modal-top">
                  <div className="free-consultation">
                    <label className="free-consultation--wraper">
                      Call us for Free Consultation
                      <span className="free-consultation--title--icon">
                        <a href="tel:7499295173">
                          <img
                            className="call-img"
                            loading="lazy"
                            src="/img/24-hours-support.gif"
                            width="38"
                            height="38"
                            alt="To paint your home, please give us a call at"
                            title="phone-call-desktop"
                          />
                          <span className="mob-num">+91 7499295173</span>
                        </a>
                      </span>
                    </label>
                  </div>
                </div> */}
                <div className="modal-middle">
                  <p className="heading">Let our experts help you!</p>
                  <button onClick={handleClose}>
                    <img src="/img/letter-x.gif" />
                  </button>
                </div>
                <div className="modal-bottom">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">
                        Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Your Full Name"
                        value={credentials.fullName}
                        onChange={onChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Eg: myname@gmail.com"
                        value={credentials.email}
                        onChange={onChange}
                      />
                    </div>
                    <div className="row align-items-center mb-3">
                      <div className="col-auto mobile">
                        <label htmlFor="mobile" className="form-label">
                          Mobile Number*
                        </label>
                        <div className="mobile-container">
                          <img src="/img/india.png" />
                          <p>+91</p>
                        </div>
                        <input
                          type="number"
                          className="form-control2"
                          id="mobile"
                          name="mobile"
                          placeholder="10 digits"
                          value={mobile}
                          onChange={handleMobile}
                          required
                        />
                      </div>
                      <div className="col-auto">
                        <label htmlFor="pincode" className="form-label">
                          Pin Code*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="6 digit pin code"
                          value={pincode}
                          onChange={handlePincode}
                        />
                      </div>
                    </div>
                    {/* {location.pathname === '/' &&(
                                                    <>
                                                        Home
                                                    </>
                                                )}
                                                {location.pathname === '/homeConstruction' &&(
                                                    <>
                                                        homeConstruction
                                                    </>
                                                )}
                                                {location.pathname === '/commercialBuilding' &&(
                                                    <>
                                                        commercialBuilding
                                                    </>
                                                )}
                                                {location.pathname === '/interior' &&(
                                                    <>
                                                        interior
                                                    </>
                                                )}
                                                {location.pathname === '/renovation' &&(
                                                    <>
                                                        renovation
                                                    </>
                                                )} */}
                    <div className="whatsapp-update mb-3">
                      <label htmlFor="fullName" className="form-label mb-3">
                        Update me on WhatsApp
                      </label>
                      <button type="button" onClick={handleWhatsappUpdate}>
                        <img src="/img/whatsapp1.png" alt="" />
                        <p className={whatsappUpdate === "off" ? "" : "on"}>
                          {whatsappUpdate === "off" ? "off" : "on"}
                        </p>
                      </button>
                    </div>
                    <div className="any-construction">
                      <p>Do you own a plot ?</p>
                      <div className="yes-no-button">
                        <button
                          type="button"
                          className={
                            constructionUpdate === "yes"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateYes}
                        >
                          yes
                        </button>
                        <button
                          type="button"
                          className={
                            constructionUpdate === "no"
                              ? "button active"
                              : "button"
                          }
                          onClick={handleConstructionUpdateNo}
                        >
                          no
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "11px",
                        marginBottom: "-7px",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="termCondition"
                        id="termCondition"
                        className="myinput"
                        onClick={handleCheck}
                      />
                      <label
                        htmlFor="termCondition"
                        className="termConditionLabel"
                        onClick={handleCheck}
                      >
                        *By submitting this form, I confirm that I have read and
                        agreed to accept 99squarewall{" "}
                        <a href="">privacy policy</a>
                      </label>
                    </div>
                    <button
                      type="button"
                      className="final-button"
                      onClick={handleClick}
                    >
                      Start Building
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        {location.pathname === "/company/terms-and-condition" ||
        location.pathname === "/company/privacy-policy" ? (
          <></>
        ) : (
          <>
            <section
              id="faq"
              className="testimonials"
              style={{ backgroundColor: "#e4ecf9", padding: "80px 0px" }}
            >
              <div className="container frequently-asked-question">
                <div className="section-title">
                  <h1 style={{ marginBottom: "15px" }}>
                    Frequently Asked<font color="#002dfb"> Questions</font>
                  </h1>
                </div>
                <div className="faq-list">
                  <div
                    className={
                      faq1 === true ? "faq-box faq-box-active" : "faq-box"
                    }
                    // style={{
                    //   height: faq1 === true ? "100%" : "52px",
                    //   overflow: "hidden",
                    // }}
                  >
                    <button className="top" onClick={handleFAQ1} type="button">
                      <h1>
                        1. Are you a third-party contractor? / Do you hand over
                        our work to a third-party contractor?
                      </h1>
                      {faq1 === true ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg>
                        </>
                      )}
                    </button>
                    <div className="faq-desc">
                      <p>
                        No, 99Squarewall takes pride in handling all aspects of
                        your project internally. We have a dedicated team of
                        professionals, ensuring that your project receives
                        personalized attention and quality without outsourcing
                        to third-party contractors.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      faq2 === true ? "faq-box faq-box-active" : "faq-box"
                    }
                    // style={{
                    //   height: faq2 === true ? "100%" : "52px",
                    //   overflow: "hidden",
                    // }}
                  >
                    <button className="top" onClick={handleFAQ2} type="button">
                      <h1>
                        2. What if material cost goes up after the agreement?
                      </h1>
                      {faq2 === true ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg>
                        </>
                      )}
                    </button>
                    <div className="faq-desc">
                      <p>
                        At 99Squarewall, we understand concerns about material
                        costs. We designed to address potential fluctuations. We
                        maintain transparent communication and collaborate with
                        clients to find fair solutions if material costs
                        increase post-agreement.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      faq3 === true ? "faq-box faq-box-active" : "faq-box"
                    }
                    // style={{
                    //   height: faq3 === true ? "100%" : "52px",
                    //   overflow: "hidden",
                    // }}
                  >
                    <button className="top" onClick={handleFAQ3} type="button">
                      <h1>
                        3. What if I don't visit the construction site daily to
                        see progress?
                      </h1>
                      {faq3 === true ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg>
                        </>
                      )}
                    </button>
                    <div className="faq-desc">
                      <p>
                        We keep our clients informed with regular updates. Our
                        project managers and coordinators provide scheduled
                        reports, photos, and virtual walkthroughs, allowing you
                        to stay connected and updated even if you can't visit
                        the site daily.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      faq4 === true ? "faq-box faq-box-active" : "faq-box"
                    }
                    // style={{
                    //   height: faq4 === true ? "100%" : "52px",
                    //   overflow: "hidden",
                    // }}
                  >
                    <button className="top" onClick={handleFAQ4} type="button">
                      <h1>
                        4. Who'll take care of architectural planning and plan
                        sanctioning?
                      </h1>
                      {faq4 === true ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg>
                        </>
                      )}
                    </button>
                    <div className="faq-desc">
                      <p>
                        Our in-house team of skilled architects and experts
                        manage the entire architectural planning process. From
                        conceptualization to plan sanctioning, we handle all
                        necessary approvals, ensuring a smooth and hassle-free
                        experience for our clients.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      faq5 === true ? "faq-box faq-box-active" : "faq-box"
                    }
                    // style={{
                    //   height: faq4 === true ? "100%" : "52px",
                    //   overflow: "hidden",
                    // }}
                  >
                    <button className="top" onClick={handleFAQ5} type="button">
                      <h1>5. How long does a typical project take?</h1>
                      {faq5 === true ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                          </svg>
                        </>
                      )}
                    </button>
                    <div className="faq-desc">
                      <p>
                        Project timelines vary based on scope and complexity.
                        During the planning phase, we provide detailed project
                        schedules, ensuring a clear understanding of the
                        expected duration. Our goal is to complete projects
                        efficiently without compromising on quality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              className="consultation-container"
              style={{ padding: "50px 0px" }}
            >
              <div className="container">
                {/* <img
                  src="/img/consultation.jpg"
                  alt="sdf"
                  style={{ width: "50%", height: "21rem" }}
                /> */}
                <div className="div">
                  <h3>Home Construction</h3>
                  <div className="p">
                    <p>
                      Exceptional designs that cater to your aesthetic,
                      functional and aspirational goals.
                    </p>
                    <button
                      className="header-btn"
                      onClick={handleIsClickedTrue}
                    >
                      <a
                        className="nav-btn"
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        Start Building
                        <span><i class="fa-solid fa-arrow-right"></i></span>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <footer id="footer">
          <div className="footer-top">
            <div className="bottom-footer-container pt-3p border-t-1 border-solid border-footer-border">
              <div className="bottom-footer-links-wrapper flex justify-center flex-wrap po:mb-2p">
                <a
                  className="cursor-pointer mx-2p my-0 text-18 text-defaultcolor no-underline tp:mr-2p tp:ml-0 po:mr-0.5p po:mb-0.5p po:text-14"
                  href="/company/about-us"
                >
                  About Us
                </a>
                <Link
                  className="cursor-pointer mx-2p my-0 text-18 text-defaultcolor no-underline tp:mr-2p tp:ml-0 po:mr-0.5p po:mb-0.5p po:text-14"
                  to="/company/terms-and-condition"
                  target="_blank"
                  rel="noopener"
                  aria-label="t&amp;c"
                >
                  Terms &amp; Conditions
                </Link>
                <Link
                  className="cursor-pointer mx-2p my-0 text-18 text-defaultcolor no-underline tp:mr-2p tp:ml-0 po:mr-0.5p po:mb-0.5p po:text-14"
                  to="/company/privacy-policy"
                  target="_blank"
                  rel="noopener"
                  aria-label="privacy"
                >
                  Privacy Policy
                </Link>
                <a
                  className="cursor-pointer mx-2p my-0 text-18 text-defaultcolor no-underline tp:mr-2p tp:ml-0 po:mr-0.5p po:mb-0.5p po:text-14"
                  href="/sitemap"
                  //   target="_blank"
                  rel="noopener"
                  aria-label="sitemap"
                >
                  Sitemap
                </a>
                <a
                  className="cursor-pointer mx-2p my-0 text-18 text-defaultcolor no-underline tp:mr-2p tp:ml-0 po:mr-0.5p po:mb-0.5p po:text-14"
                  href="#faq"
                  rel="noopener"
                  aria-label="sitemap"
                >
                  FAQs
                </a>
              </div>
              <div className="flex justify-center mt-2p">
                <a
                  className="rounded-100pe py-0.7p px-0.9p my-0 mx-2p po:mx-0.5p"
                  href="https://www.facebook.com/profile.php?id=100092224478458"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                  style={{ backgroundColor: "rgb(232, 232, 232)" }}
                >
                  <img
                    src="https://assets.nobroker.in/nb-new/public/Common/social/facebook.svg"
                    alt="facebook"
                    width="19px"
                    height="19px"
                    loading="lazy"
                  />
                </a>
                <a
                  className="rounded-100pe py-0.7p px-0.9p my-0 mx-2p po:mx-0.5p"
                  href="https://www.instagram.com/99squarewall.official?igsh=MWlrZ2dkZ2hydml0bg=="
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram"
                  style={{ backgroundColor: "rgb(232, 232, 232)" }}
                >
                  <img
                    src="https://assets.nobroker.in/nb-new/public/Common/social/instagram.svg"
                    alt="instagram"
                    width="19px"
                    height="19px"
                    loading="lazy"
                  />
                </a>
                {/* <a
                  className="rounded-100pe py-0.7p px-0.9p my-0 mx-2p po:mx-0.5p"
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="youtube"
                  style={{ backgroundColor: "rgb(232, 232, 232)" }}
                >
                  <img
                    src="https://assets.nobroker.in/nb-new/public/Common/social/youtube.svg"
                    alt="youtube"
                    width="19px"
                    height="19px"
                    loading="lazy"
                  />
                </a> */}
              </div>
            </div>
            <footer
              className="flex w-full flex-col pt-3 pb-3 _4ySX"
              blockindex="block-17"
              style={{ padding: "0px 27px" }}
            >
              <p className="text-white mb-2 flex justify-center">
                © {currentYear} www.99squarewall.com All Rights Reserved.
              </p>
              <p className="text-white flex flex-col">
                <span className="mb-2"></span>
                <span className="copyright-text">
                  For comprehensive details, please visit{" "}
                  <a href="https://www.99squarewall.com/" target="_blank">
                    www.99squarewall.com
                  </a>{" "}
                  | Specializing in custom home and commercial construction
                  projects, including renovations and remodeling | In comparison
                  with leading competitors and for precise project scope. At
                  99SquareWall, we're dedicated to bringing your vision to life
                  in Pune, Bangalore, Mumbai, Hyderabad, Chennai, Noida,
                  Kolkata, Gurgaon, and beyond. Whether you're dreaming of a
                  stunning new home or an innovative commercial space, our
                  experienced team is here to turn your ideas into reality. From
                  concept to completion, we handle every aspect of the
                  construction process with precision and care. Our services
                  cover a wide range of construction needs, from ground-up
                  construction to intricate renovations. We pride ourselves on
                  delivering top-quality craftsmanship and exceptional service,
                  ensuring that your project is completed on time and within
                  budget. Choose 99SquareWall as your trusted construction
                  partner. Contact us today to discuss your project requirements
                  and let's build something extraordinary together.
                </span>
              </p>
            </footer>
          </div>
        </footer>

        {/* --========== Talk To Us Start ==========-- */}
        <div className="whatsapp_float">
          <a action="_blank" className={fix1 ? "show" : "hide"}>
            <div className={hideBox ? "talkToUs show" : "talkToUs hide"}>
              <span>Book free consultation</span>
              <div
                className="FloatingWidget_closeIcon__6M_hu"
                onClick={hideBoxfunc}
              ></div>
              <a target="_blank" href="https://wa.me/917499295173">
                Talk to us!
              </a>
            </div>
            <a href="https://wa.me/917499295173" action="_blank">
              <img
                src="\img\whatsapp.png"
                width="50px"
                className="whatsapp_float1"
                alt="whatsapp-img"
              />
            </a>
          </a>
        </div>
        {/* --========== Talk To Us End ==========-- */}
      </div>
      {isOpen && location.pathname === "/" && (
        <>
          <div className="bottom-navigation">
            {/* <div
              style={{
                height: "auto",
                width: "13px",
                borderTopLeftRadius: "10px",
                background: "#c14a09",
                zIndex: "4",
              }}
            ></div> */}
            <div className="img5">
              <img src="/img/consult.png" alt="" />
              <p>NEW</p>
            </div>
            <div
              className="css-1dbjc4n r-b8xwuj r-1qk6wnv r-13awgt0 r-id501r text-content"
              onClick={handleIsClickedTrue}
            >
              <div
                className="css-1dbjc4n r-1awozwy r-18u37iz r-p1pxzi r-y8nl5v r-tskmnb"
                style={{ display: "flex" }}
              >
                <div
                  dir="auto"
                  className="css-901oao r-jwli3a r-98loyc r-1b43r93 r-b88u0q r-rjixqe"
                >
                  Start Your Home Construction
                </div>
                <div
                  className="css-1dbjc4n r-1mlwlqe r-1udh08x r-417010"
                  style={{ height: "24px", width: "24px" }}
                >
                  <div
                    className="css-1dbjc4n r-1niwhzg r-vvn4in r-u6sd8q r-4gszlv r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw"
                    style={{
                      backgroundImage:
                        "url(&quot;https://static.99acres.com/universalapp/img/arrowRight.png&quot;)",
                    }}
                  ></div>
                  <img
                    alt=""
                    draggable="false"
                    src="https://static.99acres.com/universalapp/img/arrowRight.png"
                    className="css-9pa8cd"
                  />
                </div>
              </div>
              <div className="css-1dbjc4n">
                <div
                  dir="auto"
                  className="css-901oao r-jwli3a r-98loyc r-1gkfh8e r-16dba41 r-1f529hi r-16l2b0g r-f727ji"
                >
                  Get in touch with our experts
                </div>
              </div>
            </div>
            <img
              alt=""
              draggable="false"
              src="https://static.99acres.com/universalapp/img/closeWhite.png"
              className="close-btn"
              onClick={handleToggle}
            />
            {/* <div
              style={{
                height: "auto",
                width: "13px",
                borderTopRightRadius: "10px",
                background: "#c14a09",
                zIndex: "4",
                marginLeft: "26px",
              }}
            ></div> */}
          </div>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-elastic-carousel";
import Carousel1 from "react-elastic-carousel";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Item1 from "./Item1";
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function TAndCs({ category, handleLoading }) {
  return (
    <>
      <Header />

      <div
        style={{
          height: "392px",
          width: "100%",
        }}
        className="about-us"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            // color: 'white',
            // font: 'bold 6.5em/2em monospace',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(to top, rgb(0 0 0 / 73%), rgb(255 255 255 / 0%)), url(/img/h.jpg) center top no-repeat",
          }}
        >
          <p>Terms & Conditions</p>
        </div>
      </div>

      <div className="main" style={{ maxWidth: "892px" }}>
        <div className="TAndCs-heading-passage">
          <p>Terms & Conditions</p>
          <span>
            This Privacy policy is subject to the terms of the Site Policy (User
            agreement) of 99squarewall. This policy is effective from the date
            and time a user registers with 99squarewall by filling up the
            Registration form and accepting the terms and conditions laid out in
            the Site Policy.
          </span>
          <span>
            In order to provide a personalised browsing experience, 99squarewall
            may collect personal information from you. Additionally some of our
            websites may require you to complete a registration form or seek
            some information from you. When you let us have your preferences, we
            will be able to deliver or allow you to access the most relevant
            information that meets your end.
          </span>
          <span>
            To extend this personalized experience 99squarewall may track the IP
            address of a user's computer and save certain information on your
            system in the form of cookies. A user has the option of accepting or
            declining the cookies of this website by changing the settings of
            your browser
          </span>
          <span>
            The personal information provided by the users to 99squarewall will
            not be provided to third parties without previous consent of the
            user concerned. Information of a general nature may however be
            revealed to external parties
          </span>
          <span>
            Every effort will be made to keep the information provided by users
            in a safe manner, the information will be displayed on the website
            will be done so only after obtaining consent from the users. Any
            user browsing the site generally is not required to disclose his
            identity or provide any information about him/her, it is only at the
            time of registration you will be required to furnish the details in
            the registration form.
          </span>
          <span>
            TA full user always has the option of not providing the information
            which is not mandatory. You are solely responsible for maintaining
            confidentiality of the User password and user identification and all
            activities and transmission performed by the User through his user
            identification and shall be solely responsible for carrying out any
            online or off-line transaction involving credit cards / debit cards
            or such other forms of instruments or documents for making such
            transactions and IEIL assumes no responsibility or liability for
            their improper use of information relating to such usage of credit
            cards / debit cards used by the subscriber online / off-line.
          </span>
          <span>
            99squarewall shall in order to provide seamless video upload and
            streaming services to users, utilize 3rd party services such as
            You-tube API Services and shall not pull any metadata of the video
            provided by the user. We suggest you check up the Google Privacy
            Policy at (http://www.google.com/policies/privacy) to help you
            understand what data Google holds about you and how they process it.
          </span>

          <span>
            IEIL provides you the ability to keep your personal information
            accurate and up-to-date. If at any time you would like to a)
            rectify, update, correct or delete your personal information; b)
            obtain confirmation on whether or not your personal information is
            processed by it; c) access your personal information or exercise
            your right to data portability; or d) exercise your right to
            restrict the continuing disclosure of your personal information to
            any third party by IEIL in certain circumstances, you are requested
            to contact us at feedback@99squarewall.com. We will require you to
            provide a valid proof of your identity, in order to ensure that your
            rights are respected.
          </span>
          <span>
            You agree that IEIL may use personal information about you to
            improve its marketing and promotional efforts, to analyze site
            usage, improve the Site's content and product offerings, and
            customise the Site's content, layout, and services. These uses
            improve the Site and better tailor it to meet your needs, so as to
            provide you with a smooth, efficient, safe and customised experience
            while using the Site.
          </span>
          <span>
            You agree that IEIL may use your personal information to contact you
            and deliver information to you that, in some cases, are targeted to
            your interests, such as targeted banner advertisements,
            administrative notices, product offerings, and communications
            relevant to your use of the Site. By accepting the User Agreement
            and Privacy Policy, you expressly agree to receive this information.
            If you do not wish to receive these communications, we encourage you
            to opt out of the receipt of certain communications in your profile.
            You may make changes to your profile at any time, if you wish to
            delete your account on 99squarewall.com, you may do so by writing an
            email to feedback@99squarewall.com or by using the settings
            available in your account. It is the belief of IEIL that privacy of
            a person can be best guaranteed by working in conjunction with the
            Law enforcement authorities.
          </span>
          <span>
            All IEIL websites including 99squarewall fully comply with all
            Indian Laws applicable. IEIL has always cooperated with all law
            enforcement inquires. IEIL may disclose all or part of your personal
            details in response to a request from the law enforcement
            authorities or in a case of bonafide requirement to prevent an
            imminent breach of the law
          </span>
        </div>
      </div>

      <Footer />
    </>
  );
}
